import React, {useState} from 'react';
import {Buttoni} from "../../pages/Boards/board.style";
import styled from "styled-components";
import SecondaryButton from "../Buttons/Secondary-Button/secondary-button";


const Input = styled.input`
  width: 40%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const Select = styled.select`
  width: 30%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const RemoveButton = styled.div`
  background-color: #f44336;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-left: 10px;
  border-radius: 50%;
  cursor: pointer;
`;

const EmailRoleFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  height: 100%;`

;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  height: 10%;
`;


const EmailRoleForm = ({roles, onSubmit}) => {
    const [inputs, setInputs] = useState([{email: '', role: ''}]);
    const handleInputChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index][event.target.name] = event.target.value;
        setInputs(newInputs);
    };

    const handleAddRow = () => {
        setInputs([...inputs, {email: '', role: ''}]);
    };

    const handleRemoveRow = (index) => {
        if (inputs.length > 1) {
            const newInputs = [...inputs];
            newInputs.splice(index, 1);
            setInputs(newInputs);
        }
    };

    const handleSubmit = () => {
        onSubmit(inputs);
    };

    return (
        <EmailRoleFormWrapper>
            <div>
                <Input type="text" defaultValue={
                    "You"
                } disabled/>
                <Select defaultValue="Role" disabled>
                    <option value="">Admin</option>
                </Select>
            </div>
            {inputs.map((input, index) => (
                <div key={index}>
                    <Input
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={input.email}
                        onChange={(event) => handleInputChange(index, event)}
                    />
                    <Select
                        name="role"
                        value={input.role}
                        onChange={(event) => handleInputChange(index, event)}
                    >
                        <option value="">Select Role</option>
                        {roles.map((role) => (
                            <option key={role.label} value={role.value}>
                                {role.label}
                            </option>
                        ))}
                    </Select>
                    {inputs.length > 1 && (
                        <RemoveButton type="button" onClick={() => handleRemoveRow(index)}>
                            X
                        </RemoveButton>
                    )}
                </div>
            ))}
            <ButtonsWrapper>
                <SecondaryButton type="button" onClick={handleAddRow}>
                    Add Members
                </SecondaryButton>
                {"   "}
                <Buttoni type="button" onClick={handleSubmit}>
                    Submit
                </Buttoni>
            </ButtonsWrapper>

        </EmailRoleFormWrapper>
    );
};

export default EmailRoleForm;
