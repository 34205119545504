import styled from "styled-components";

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  width: 100%;
  height: 100%;
`

export const Modal = styled.div`
  width: 40%;
  //min-height: 50%;
  max-height: 95%;
  background-color: #fff;
  padding: 1rem 2.5rem 0 2.5rem;
  border-radius: 20px;
  backdrop-filter: blur(5px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  scroll-behavior: smooth;
    scrollbar-width: thin;

`
export const ModalHeader = styled.header`
  position: relative;
  border-bottom: 1px solid #dddddd;

  &-title {
    text-align: center;
  }

  h2 {
    font-weight: 400;
  }
  
`;

export const ModalBody = styled.main`
  border-bottom: 1px solid #dddddd;
  padding: 1rem;
  margin-bottom: 1rem;


  img {
    width: 20%;
    height: 30%;
  }

  video {
    width: 20%;
    height: 100%;
  }

  p {
    font-size: 20px;
  }

`;
export const ModalFooter = styled.footer`
    padding: 0 0 15px 0;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;

    .submit {
        margin-right: 1rem;
        background-color: #364348;
        color: #fff;

        &:hover {
            background-color: rgba(54, 67, 72, 0.8);
        }
    }
`;
export const CloseButton = styled.button`
  position: absolute;
  top: -25px;
  right: -10px;
  background: transparent;
  border: none;
  font-size: 2.5rem;

  img {
    width: 1rem;
    height: auto;
    transition: all 0.3s;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }
`
export const InputWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  input, select {
    margin: 0.5rem 0;
  }

  select {
    padding: 0.5rem;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    :focus-visible {
      outline: none;
    }
  }
  
`;
