import axios from "axios";
import {toast} from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader,
} from "../../../../Components/UI/modal.style";
import {useParams} from "react-router-dom";
import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../../../../Components/custome-tags/TextInput/textInput";
import {SelectFile} from "../../../../Components/UI/basic.style";
import PrimaryButton from "../../../../Components/Buttons/Primary-Button/primary-button";
import {containsOnlySpaces} from "../../../../Components/Helpers/helper";

const CreateCreative = ({show, close, project, content, onSubmit}) => {
    const [video, setVideo] = useState(null);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [caption, setCaption] = useState("");
    const [key, setKey] = useState(0);



    const uploadFile = (e) => {
        const files = e.target.files;
        const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/x-m4v', 'video/avi'];
        if (e.target.files[0].size > 50 * 1024 * 1024) {
            setKey(prevKey => prevKey + 1);
            return toast.error('File size exceeds 50 MB. Please choose a smaller file.');
        }
        const filteredFiles = Array.from(files).filter(file => acceptedTypes.includes(file.type));
        if (filteredFiles.length !== files.length) {
            return toast.error('Please select only image and video files.');
        }
        setVideo(e.target.files[0]);
    };


    useEffect(() => {
        if (content) {
            setTitle(content?.title);
            setCaption(content?.caption);
        } else {
            setTitle("");
            setVideo(null);
            setDescription("");
        }
    }, [show]);

    const createCreative = async () => {


        if (content) {
            const formData = new FormData();
            formData.append("title", title);
            formData.append("caption", caption);

            if (title === "" || containsOnlySpaces(title)) {
                toast.error("Title is required");
                return;
            }


            // Show a loading toast before making the API call
            setLoading(true);
            const loadingToast = toast.loading("Updating video...");
            try {
                await axios.put(
                    `${process.env.REACT_APP_API_URL}creative/${content?._id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );


                setLoading(false);
                toast.success("Content updated successfully", {id: loadingToast});
                close();
                setTitle("");
                setVideo(null);

                onSubmit({
                    title: title,
                    caption: caption
                })


            } catch (e) {
                // Update the toast to an error state
                setLoading(false);
                toast.error("Something went wrong", {id: loadingToast});
            }
            return;
        }


        const formData = new FormData();

        formData.append("title", title);
        formData.append("video", video);

        if (title === "" || containsOnlySpaces(title)) {
            toast.error("Title is required");
            return;
        } else if (video === null) {
            toast.error("Content File is required");
            return;
        }

        // Show a loading toast before making the API call
        setLoading(true);
        const loadingToast = toast.loading("Uploading Content...");

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}creative/${params.id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            setLoading(false);
            toast.success("Content uploaded successfully", {id: loadingToast});
            close();
            setTitle("");
            setVideo(null);
        } catch (e) {
            // Update the toast to an error state
            setLoading(false);
            toast.error("Something went wrong", {id: loadingToast});
        }
    };

    return (
        <>
            {show ? (
                <ModalContainer>
                    <Modal>
                        <ModalHeader>

                            {content ? (
                                <h3 className="modal_header-title">Update Content Detail of {content?.title}</h3>
                            ) : (
                                <h3 className="modal_header-title">Upload Content</h3>
                            )}
                            <CloseButton onClick={close}>
                                <IoIosCloseCircleOutline/>
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <InputWrapper>
                                {
                                    content ? null : (
                                        <SelectFile key={key} type="file" accept="image/*,.mp4,.x-m4v,.avi"
                                                    onChange={uploadFile}/>
                                    )
                                }
                                <TextInput
                                    placeholder="Content Title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <span style={{
                                    fontSize: '10px'
                                }}>Simply put any name to the content you are uploading, eg. Diwali Post
                                    or
                                    enter the date for the post, eg. 5 Nov
                                </span>
                                {
                                    content ? (<TextInput
                                        type='textarea'
                                        placeholder="Caption"
                                        value={caption}
                                        onChange={(e) => setCaption(e.target.value)}
                                    />) : null
                                }
                            </InputWrapper>
                        </ModalBody>
                        <ModalFooter>
                            {
                                content ? (
                                    <PrimaryButton loading={loading} onClick={createCreative}>Update
                                        Content</PrimaryButton>
                                ) : (
                                    <PrimaryButton loading={loading} onClick={createCreative}>Upload
                                        Content</PrimaryButton>
                                )
                            }
                        </ModalFooter>
                    </Modal>
                </ModalContainer>
            ) : null}
        </>
    );
};

export default CreateCreative;
