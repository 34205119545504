import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const marqueeAnimation = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
`;

const MarqueeContainer = styled.div`
    overflow: hidden;
`;

const MarqueeContent = styled.div`
    display: inline-block;
    white-space: nowrap;
    max-width: 200px;
    ${({ shouldAnimate, animationDuration }) =>
            shouldAnimate &&
            css`
                animation: ${marqueeAnimation} ${animationDuration}s linear infinite;
            `}
`;

const Marquee = ({ children, move }) => {
    const [animate, setAnimate] = useState(false);
    const contentRef = useRef(null);

    const calculateAnimationDuration = () => {
        if (contentRef.current) {
            const contentWidth = contentRef.current.offsetWidth;
            const containerWidth = contentRef.current.parentElement.offsetWidth;
            return (contentWidth / containerWidth) * 10 || 0; // Adjust multiplier as needed
        }
        return 0;
    };

    useEffect(() => {
        if (move) {
            setAnimate(true);
        }
    }, [move]);

    return (
        <MarqueeContainer>
            <MarqueeContent
                ref={contentRef}
                shouldAnimate={animate}
                animationDuration={calculateAnimationDuration()}
            >
                {children}
            </MarqueeContent>
        </MarqueeContainer>
    );
};

Marquee.propTypes = {
    children: PropTypes.node.isRequired,
    move: PropTypes.bool,
};

Marquee.defaultProps = {
    move: false,
};

export default Marquee;
