import styled from "styled-components";

export const SegmentWrapper = styled.div`
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #EEEEEE;
    padding: 8px 10px;
    border-radius: 10px;
    margin-bottom: 10px;
`

export const SegmentButton = styled.button`
    width: 60%;
    min-width: 50%;
    color: ${(props) => (props?.isActive ? "white" : "black")};
    border-radius: ${({isActive}) => (isActive ? '10px' : '0')};
    border: none;
    border-left: ${({isActive}) => (!isActive && '.5px')} solid #c9aaaa;
    outline: none;
    font-size: ${({fontSize}) => (fontSize ? fontSize : "1.5em")};
    padding: 5px 10px;
    background: ${({isActive}) => (isActive ? "#005cc7" : "transparent")};
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
        margin-right: 5px;
        border-right: ${({isActive}) => (!isActive && '.5px')} solid #c9aaaa;
        border-left: none !important;
    }

`