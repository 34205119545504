import styled from 'styled-components';


export const ProfileIconContainer = styled.div`
`;


export const ProfileIconText = styled.span`
    font-size: 25px;
    font-weight: bold;
    color: #333;
    text-align: center;

    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
    }x
`;

export const DropdownMenuContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownMenuButton = styled.div`
  width: 60px;
  height: 60px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;

export const DropdownMenuList = styled.ul`
  position: absolute;
  bottom: 5rem;
  width: 17rem;
  z-index: 1000;
  border-radius: 7%;
  background-color: #E9F5F6;
  border: 1px solid #e0e0e0;
  list-style: none;
  margin: 0;
  padding: 0;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
`;

export const DropdownMenuItem = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;
export const ProfileItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: .5rem;

  h5 {
    margin-bottom: 1px;

  }

  h6 {
    margin-top: 1px;
  }

`
