import axios from "axios";
import {toast} from "react-hot-toast";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../../../Components/custome-tags/TextInput/textInput";
import DefaultSelect from "../../../Components/default-select/default-select";
import industriesObjects from "../../../constants/industries";
import {
    CloseButton, InputWrapper, Modal, ModalBody, ModalContainer, ModalFooter, ModalHeader
} from "../../../Components/UI/modal.style";
import PrimaryButton from "../../../Components/Buttons/Primary-Button/primary-button";
import {containsOnlySpaces} from "../../../Components/Helpers/helper";
import Toggle from "../../../Components/Toggle/toggle";

const CreateProject = ({show, close, organisation, project}) => {

    const [name, setName] = useState("");
    const [industryType, setIndustryType] = useState("");
    const [visibility, setVisibility] = useState("");
    const [loader, setLoader] = useState(false);
    const viewActive = useRef(true)
    const [, setRerender] = useState(false); // Dummy state to force re-render


    const params = useParams();

    useEffect(() => {
        setLoader(false);
        if (project) {
            viewActive.current = project.isActive
            setName(project.name);
            setIndustryType(project.description);
            setVisibility(project.visibility);
        } else {
            setName("");
            setIndustryType("");
            setVisibility("");
        }

    }, [show, project]);

    const createProject = async () => {
        if (name === "" || containsOnlySpaces(name)) {
            toast.error("Project name is required");
            setLoader(false)
            return;
        }
        setLoader(true);
        if (project) {
            try {
                await axios.put(`${process.env.REACT_APP_API_URL}project/${project._id}`, {
                    name: name, description: industryType, isActive: viewActive.current,visibility:visibility
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                });
                setLoader(false);
                close();
                toast.success("Project Updated Successfully");
            } catch (e) {
                toast.error("Something went wrong")
                setLoader(false);
            }
            return;
        }

        setLoader(true)

        if (name === "" || containsOnlySpaces(name)) {
            toast.error("Project name is required");
            setLoader(false)
            return;
        } else if (industryType === "") {
            toast.error("Industry is required");
            setLoader(false)
            return;
        } else if (visibility === "") {
            toast.error("Visibility is required");
            setLoader(false)
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}project/${params.id}`, {
                name: name, description: industryType, visibility: visibility,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            });

            setLoader(false);
            close();
            toast.success("Project Created Successfully");

        } catch (e) {
            toast.error("Something went wrong")
            setLoader(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            createProject()
        }
    }

    return (<>
        {show ? <ModalContainer
        >
            <Modal onKeyDown={handleKeyDown} tabIndex={0}>
                <ModalHeader>
                    {!project ? (<h2 className="modal_header-title">Create new Project
                        for {organisation?.name}</h2>) : (<h2 className="modal_header-title">
                        Edit {project?.name}
                    </h2>)}

                    <CloseButton onClick={() => {
                        setName("");
                        setIndustryType("");
                        close()
                    }}>
                        <IoIosCloseCircleOutline/>
                    </CloseButton>
                </ModalHeader>
                <ModalBody>
                    <InputWrapper>
                        {
                            project ? (
                                <div style={{
                                    margin: '10px',
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: "right",
                                }}>
                                    <Toggle value={viewActive.current}
                                            change={(val) => {
                                                viewActive.current = val
                                                setRerender(prev => !prev);
                                            }}>{viewActive?.current ? 'Active' : 'Inactive'} {viewActive.current} Projects</Toggle>
                                </div>
                            ) : null
                        }
                        <TextInput label={"Enter Project Name"} type='text' placeholder='Project Name'
                                   value={name}
                                   onChange={e => setName(e.target.value)}/>

                       <div>
                            <DefaultSelect selectedValue={industryType} placeholder='Project Category'
                                           onSelect={(selectedOption) => {
                                               setIndustryType(selectedOption.value)
                                           }}
                                           label={"Select Industry"}
                                           options={industriesObjects}/>
                        </div>
                        <div style={{
                            marginTop: '10px'
                        }}>
                            <div style={{
                                marginBottom: '5px'
                            }}>
                                <DefaultSelect selectedValue={visibility} label={"Select Visibility"}
                                               onSelect={(selectedOption) => {
                                                   setVisibility(selectedOption.value)

                                               }} options={[{
                                    value: "public", label: "Public", explanation: "Anyone can see this project"
                                }, {
                                    value: "private",
                                    label: "Private",
                                    explanation: "Only Members of this project can see this project"
                                },]} placeholder={"Select Visibility"}/>
                            </div>
                        </div>
                    </InputWrapper>
                </ModalBody>
                <ModalFooter>
                    <PrimaryButton onClick={createProject} loading={loader}>
                        {!project ? "Create" : "Update"}
                    </PrimaryButton>
                </ModalFooter>
            </Modal>
        </ModalContainer> : null}
    </>);
}
export default CreateProject;
