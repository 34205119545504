import {createSlice} from "@reduxjs/toolkit";
import {getCurrentUser} from "../../api";

const initialState = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    username: '',
    c_displayPicture: '',
    displayPicture: '',
    aboutProfile: '',
}


const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            state._id = action.payload._id;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.mobile = action.payload.mobile;
            state.username = action.payload.username;
            state.c_displayPicture = action.payload.c_displayPicture;
            state.displayPicture = action.payload.displayPicture;
            state.aboutProfile = action.payload.aboutProfile;
        },

        updateUserData: (state, action) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.email = action.payload.email;
            state.mobile = action.payload.mobile;
            state.username = action.payload.username;
            state.displayPicture = action.payload.displayPicture;
            state.aboutProfile = action.payload.aboutProfile;
        },
    },
});

export default userSlice.reducer;


export const {setUserData ,updateUserData} = userSlice.actions;

export const fetchUserData = () => async (dispatch) => {
    try {
        const userData = await getCurrentUser();
        dispatch(setUserData({
            _id: userData.data._id,
            firstName: userData.data.firstName,
            lastName: userData.data.lastName,
            email: userData.data.email,
            mobile: userData.data.mobile,
            username: userData.data.username,
            c_displayPicture: userData.data.displayPicture,
            displayPicture: userData.data.displayPicture,
            aboutProfile: userData.data.aboutProfile,
        }));
    } catch (error) {
        dispatch(setUserData(
            {
                _id: '',
                firstName: '',
                lastName: '',
                email: '',
                mobile: '',
                username: '',
                c_displayPicture: '',
                displayPicture: '',
                aboutProfile: '',
            }
        ));
    }
};






