import {BreadCrumbsHolder, Header, MainBody, ScreenWrapper} from "../../Components/UI/basic.style";
import {
    Calendar,
    CalendarContainer,
    CalendarHeaderMenu,
    Day,
    DayColumn,
    DayDateHolder,
    DayPost,
    DayPostHolder,
    DirectionButton,
    MonthDirectionHolder,
    PlusIcon,
    PreviewPostCard,
    PreviewPostCardBody,
    PreviewPostCardBodySection,
    PreviewPostCardHeader,
    PreviewPostOptionHolder,
    ShowDateHolidays,
    WeekDay
} from "./social-calander-style";
import React, {useEffect, useState} from "react";
import api from "../../api";
import Api from "../../api";
import {Back, saveCurrentMonth} from "../../Components/Helpers/helper";
import {
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineClose,
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlinePlus
} from "react-icons/ai";
import PostForm from "./post-form/post-form";
import {useParams} from "react-router-dom";
import {BsStars} from "react-icons/bs";
import AiGenerateCalendar from "./ai-generate-calader-form/ai-generate-calander-form";
import {Toaster} from "react-hot-toast";
import {IoReturnUpBackOutline} from "react-icons/io5";
import {Breadcrumbs} from "@mui/joy";
import Link from "@mui/joy/Link";
import {BiHome} from "react-icons/bi";
import Typography from "@mui/joy/Typography";

const SocialCalendarView = () => {
    const [calendarData, setCalendarData] = useState([]);
    const [showPostForm, setShowPostForm] = useState(false);
    const [showGenerateCalendarForm, setShowGenerateCalendarForm] = useState(false);
    const params = useParams();
    const [selectedCalendarObject, setSelectedCalendarObject] = useState(null);
    const [selectedPostObject, setSelectedPostObject] = useState(null);
    const [showPreviewPostCard, setShowPreviewPostCard] = useState(false);
    const [currentProject, setCurrentProject] = useState(null);


    // const currentMonth = new Date().getMonth(); in number
    const currentMonth = saveCurrentMonth();


    const [activeMonth, setActiveMonth] = useState(currentMonth);

    console.log(activeMonth);


    const TogglePostForm = () => {
        setShowPostForm(!showPostForm);
    };

    const ToggleGenerateCalendarForm = () => {
        setShowGenerateCalendarForm(!showGenerateCalendarForm);
    };

    const togglePreviewPostCard = () => {
        setShowPreviewPostCard(!showPreviewPostCard);
    };

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const getCalendarData = () => {
        api.getCalendarDatesWithPostHolidays(params.id, activeMonth.monthNumber)
            .then(res => {
                console.log(res.data.calenders);
                setCalendarData(res.data.calenders.sort((a, b) => new Date(a.c_date) - new Date(b.c_date)));
            })
            .catch(error => console.error("Error fetching calendar data:", error));
    };

    const fetchCurrentProject = async () => {
        const userProjectResponse = await Api.fetchUserProjectById(params.id);
        setCurrentProject(userProjectResponse);
    };

    useEffect(() => {
        fetchCurrentProject();
    }, [params.id]);

    useEffect(() => {
        getCalendarData();
    }, [showPostForm]);


    const changeMonth = (month) => {

        if (month < 1) {
            month = 12;
        } else if (month > 12) {
            month = 1;
        }

        api.getCalendarDatesWithPostHolidays(params.id, month)
            .then(res => {
                setCalendarData(res.data.calenders.sort((a, b) => new Date(a.c_date) - new Date(b.c_date)));
                setActiveMonth(
                    {
                        monthNumber: month,
                        monthName: new Date(res.data.calenders[0].c_date).toLocaleDateString('en-US', {month: 'long'})
                    }
                )
            })
            .catch(error => console.error("Error fetching calendar data:", error));
    };


    return (
        <>
            <Toaster/>
            <ScreenWrapper>
                <BreadCrumbsHolder>
                    <Breadcrumbs separator="›" size='sm'>
                        <Link color="primary" href="/">
                            <BiHome/>
                        </Link>
                        <Link color="primary" href="/menu/organisation">
                            <Typography>
                                <Typography>Organisations</Typography>
                            </Typography>
                        </Link>
                        <Link color="primary" href={`/menu/project/:id`}>
                            <Typography>
                                <Typography>
                                    All Projects
                                </Typography>
                            </Typography>
                        </Link>
                        <Typography>
                            <Typography>
                                {currentProject?.name}'s Calendar
                            </Typography>
                        </Typography>
                    </Breadcrumbs>
                </BreadCrumbsHolder>
                <Header>
                    <h1 style={{
                        fontSize: '24px',
                        fontWeight: '400',
                        color: '#54289a',
                        cursor: 'pointer'
                    }}
                        onClick={() => {
                            ToggleGenerateCalendarForm();
                        }}
                    ><BsStars/> Generate AI Calendar For
                        {" " + currentProject?.name}
                    </h1>
                    <IoReturnUpBackOutline onClick={Back} size={30}
                                           style={{marginRight: '10px', cursor: 'pointer'}}/>
                </Header>
                <MainBody>
                    <CalendarContainer>
                        <CalendarHeaderMenu>
                            <MonthDirectionHolder>
                                <DirectionButton onClick={() => {
                                    changeMonth(activeMonth.monthNumber - 1);
                                }}>
                                    <AiOutlineArrowLeft/>
                                </DirectionButton>
                                {" "}
                                <h2>{activeMonth.monthName} 2024</h2>
                                {""}
                                <DirectionButton onClick={() => {
                                    changeMonth(activeMonth.monthNumber + 1);
                                }}>
                                    <AiOutlineArrowRight/>
                                </DirectionButton>
                            </MonthDirectionHolder>
                        </CalendarHeaderMenu>
                        <Calendar>
                            {daysOfWeek.map((day, dayIndex) => (
                                <DayColumn key={dayIndex}>
                                    <WeekDay isWeekend={day === 'Sunday' || day === 'Saturday'}>{day}</WeekDay>
                                    {dayIndex < calendarData.length && dayIndex < new Date(calendarData[0].c_date).getDay() &&
                                        <Day/>}
                                    {calendarData.map((dayData) => {
                                        const dayOfWeek = new Date(dayData.c_date).toLocaleDateString('en-US', {weekday: 'long'});
                                        if (dayOfWeek === day) {
                                            const dayOfMonth = new Date(dayData.c_date).getDate();
                                            return (
                                                <Day key={dayData._id} onClick={() => {
                                                    console.log(dayData);
                                                }}>
                                                    <DayDateHolder isHoliday={dayData.holidays.length > 0}>
                                                        <p>{dayOfMonth}</p>
                                                        {/*<ShowDateHolidays>*/}
                                                        {/*    {dayData.holidays.length > 0 && dayData.holidays.map((holiday, index) => {*/}
                                                        {/*        return (*/}
                                                        {/*            <span>*/}
                                                        {/*            {holiday.holiday_name}*/}
                                                        {/*       </span>*/}
                                                        {/*        )*/}
                                                        {/*    })}*/}
                                                        {/*</ShowDateHolidays>*/}
                                                    </DayDateHolder>

                                                    <PlusIcon onClick={() => {
                                                        setSelectedCalendarObject(dayData);
                                                        TogglePostForm();
                                                    }}>
                                                        <AiOutlinePlus/>
                                                    </PlusIcon>
                                                    <DayPostHolder>
                                                        {dayData.posts.map((post) => (
                                                            <DayPost key={post._id} onClick={() => {
                                                                setSelectedPostObject(post);
                                                                setSelectedCalendarObject(dayData);
                                                                togglePreviewPostCard();
                                                            }}>
                                                                {post.title}
                                                            </DayPost>
                                                        ))}
                                                    </DayPostHolder>
                                                </Day>
                                            );
                                        }
                                        return null;
                                    })}
                                </DayColumn>
                            ))
                            }
                        </Calendar>
                    </CalendarContainer>
                </MainBody>
            </ScreenWrapper>
            <PostForm show={showPostForm} close={TogglePostForm} projectId={params.id}
                      existingPost={selectedPostObject}
                      calendarObject={selectedCalendarObject} onClose={(data) => {
                if (data.result) {
                    TogglePostForm();
                }
            }}
            />

            <AiGenerateCalendar show={showGenerateCalendarForm}
                                close={ToggleGenerateCalendarForm} projectId={params.id}
                                onClose={(data) => {
                                    getCalendarData()
                                }
                                }
            />

            {selectedCalendarObject && (
                <PreviewPostCard>
                    <PreviewPostOptionHolder>
                        <AiOutlineEdit onClick={() => {
                            TogglePostForm();
                        }}/>
                        <AiOutlineClose onClick={() => {
                            setSelectedCalendarObject(false)
                            setSelectedPostObject(null);
                            setShowPreviewPostCard(false);
                            setShowPostForm(false);
                        }}/>

                        <AiOutlineDelete onClick={() => {
                            api.deletePost(selectedPostObject._id)
                                .then(res => {
                                    setSelectedPostObject(null);
                                    setSelectedCalendarObject(false)
                                    getCalendarData();
                                })
                                .catch(error => console.error("Error deleting post:", error));
                        }}/>
                    </PreviewPostOptionHolder>
                    <PreviewPostCardHeader>
                        <h1>{selectedPostObject?.title}</h1>
                        <p>{
                            new Date(selectedCalendarObject?.c_date).toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                            })
                        }</p>
                    </PreviewPostCardHeader>
                    <PreviewPostCardBody>
                        <PreviewPostCardBodySection>
                            <h2>Description</h2>
                            <p>{selectedPostObject?.description} </p>
                        </PreviewPostCardBodySection>
                        <PreviewPostCardBodySection>
                            <h2>Content</h2>
                            <p>{selectedPostObject?.content}</p>
                        </PreviewPostCardBodySection>
                        <PreviewPostCardBodySection>
                            <h2>Hashtags</h2>
                            <p>{selectedPostObject?.has_tags}</p>
                        </PreviewPostCardBodySection>
                        <PreviewPostCardBodySection>
                            <h2>Platform</h2>
                            <p>{selectedPostObject?.sme_platforms}</p>
                        </PreviewPostCardBodySection>
                        <PreviewPostCardBodySection>
                            <h2>Type</h2>
                            <p>{selectedPostObject?.content_type}</p>
                        </PreviewPostCardBodySection>
                    </PreviewPostCardBody>
                </PreviewPostCard>
            )}
        </>
    );
};

export default SocialCalendarView;
