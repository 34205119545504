import React, {useEffect, useState} from "react";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import Stack from "@mui/joy/Stack";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import axios from "axios";
import {toast} from "react-hot-toast";
import {CircularProgress} from "@mui/joy";

function ContactUs({open, close}) {
    const defaultFormValue = {
        email: '',
        mobileNumber: '',
        subject: '',
        message: ''
    }
    const [formData, setFormData] = useState(defaultFormValue);
    useEffect(() => {
        setFormData(defaultFormValue)
    }, [open]);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}intake`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                }
            ).then(() => {
                toast.success("Submitted Successfully !");
                close(false);
                setLoading(false)
            });

        } catch (e) {
            toast.error("Something went wrong")
            formData.clear();
            close(false);
            setLoading(false)
        }
    }

    return (
        <Modal open={open} onClose={() => close(false)}>
            <ModalDialog>
                <DialogTitle>Contact Us</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input
                                name="email"
                                type="email"
                                placeholder="name@example.com"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                autoFocus
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input
                                name="mobileNumber"
                                type="number"
                                placeholder="123456789"
                                value={formData.mobileNumber}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Subject</FormLabel>
                            <Input
                                name="subject"
                                type="text"
                                placeholder="Let us know how we can help you"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                minRows={2}
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </FormControl>
                        <Button type="submit" disabled={loading}>{loading ?
                            <CircularProgress size="sm"/> : 'Submit'}</Button>
                    </Stack>
                </form>
            </ModalDialog>
        </Modal>
    );
}

export default ContactUs;
