import styled from "styled-components";
import CircularLoader from "../../Loaders/Circular-Loader";

const Button = styled.button`
    padding: 12px 20px;
    text-align: center;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    position: sticky;
    top: 0;
    border: 1px solid #f6f6f6;
    color: #54289a;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background 0.2s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; /* Add smooth transitions for background color, transform, box-shadow, and color */
  
  
    &:hover {
        background: #54289a;
        color: white;
        transform: scale(1.05);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
`;


const SecondaryButton = ({children, onClick, loading}) => {
    return (
        <Button onClick={onClick} disabled={loading}>{loading ? null : children}
            {loading && <CircularLoader/>}
        </Button>
    );
};


export default SecondaryButton;
