const languageArray = [
    {
        "value": "English(US)",
        "label": "English(US)",
        "explanation": "Your caption will be created in English(US) language"
    },
    {
        "value": "English",
        "label": "English",
        "explanation": "Your caption will be created in English language"
    },
    {
        "value": "Hindi",
        "label": "Hindi",
        "explanation": "Your caption will be created in Hindi language"
    },
    {
        "value": "Bengali",
        "label": "Bengali",
        "explanation": "Your caption will be created in Bengali language"
    },
    {
        value: "Hinglish",
        label: "Hinglish",
        explanation: "Your caption will be created in Hinglish language"
    },
    {
        "value": "Telugu",
        "label": "Telugu",
        "explanation": "Your caption will be created in Telugu language"
    },
    {
        "value": "Marathi",
        "label": "Marathi",
        "explanation": "Your caption will be created in Marathi language"
    },
    {
        "value": "Tamil",
        "label": "Tamil",
        "explanation": "Your caption will be created in Tamil language"
    },
    {
        "value": "Urdu",
        "label": "Urdu",
        "explanation": "Your caption will be created in Urdu language"
    },
    {
        "value": "Gujarati",
        "label": "Gujarati",
        "explanation": "Your caption will be created in Gujarati language"
    },
    {
        "value": "Malayalam",
        "label": "Malayalam",
        "explanation": "Your caption will be created in Malayalam language"
    },
    {
        "value": "Kannada",
        "label": "Kannada",
        "explanation": "Your caption will be created in Kannada language"
    },
    {
        "value": "Odia",
        "label": "Odia",
        "explanation": "Your caption will be created in Odia language"
    },
    {
        "value": "Punjabi",
        "label": "Punjabi",
        "explanation": "Your caption will be created in Punjabi language"
    },
    {
        "value": "Assamese",
        "label": "Assamese",
        "explanation": "Your caption will be created in Assamese language"
    },
    {
        "value": "Maithili",
        "label": "Maithili",
        "explanation": "Your caption will be created in Maithili language"
    },
    {
        "value": "Santali",
        "label": "Santali",
        "explanation": "Your caption will be created in Santali language"
    },
    {
        "value": "Kashmiri",
        "label": "Kashmiri",
        "explanation": "Your caption will be created in Kashmiri language"
    },
    {
        "value": "Nepali",
        "label": "Nepali",
        "explanation": "Your caption will be created in Nepali language"
    },
    {
        "value": "Konkani",
        "label": "Konkani",
        "explanation": "Your caption will be created in Konkani language"
    },
    {
        "value": "Dogri",
        "label": "Dogri",
        "explanation": "Your caption will be created in Dogri language"
    },
    {
        "value": "Manipuri",
        "label": "Manipuri",
        "explanation": "Your caption will be created in Manipuri language"
    },
    {
        "value": "Bodo",
        "label": "Bodo",
        "explanation": "Your caption will be created in Bodo language"
    },
    {
        "value": "Khasi",
        "label": "Khasi",
        "explanation": "Your caption will be created in Khasi language"
    },
    {
        "value": "Magahi",
        "label": "Magahi",
        "explanation": "Your caption will be created in Magahi language"
    },
    {
        "value": "Vojpuri",
        "label": "Vojpuri",
        "explanation": "Your caption will be created in Vojpuri language"
    },
    {
        "value": "Spanish",
        "label": "Spanish",
        "explanation": "Your caption will be created in Spanish language"
    },
    {
        "value": "French",
        "label": "French",
        "explanation": "Your caption will be created in French language"
    },
    {
        "value": "German",
        "label": "German",
        "explanation": "Your caption will be created in German language"
    },
    {
        "value": "Chinese",
        "label": "Chinese",
        "explanation": "Your caption will be created in Chinese language"
    },
    {
        "value": "Japanese",
        "label": "Japanese",
        "explanation": "Your caption will be created in Japanese language"
    },
    {
        "value": "Russian",
        "label": "Russian",
        "explanation": "Your caption will be created in Russian language"
    },
    {
        "value": "Arabic",
        "label": "Arabic",
        "explanation": "Your caption will be created in Arabic language"
    },
    {
        "value": "Portuguese",
        "label": "Portuguese",
        "explanation": "Your caption will be created in Portuguese language"
    },
    {
        "value": "Italian",
        "label": "Italian",
        "explanation": "Your caption will be created in Italian language"
    },
    {
        "value": "Dutch",
        "label": "Dutch",
        "explanation": "Your caption will be created in Dutch language"
    },
    {
        "value": "Swedish",
        "label": "Swedish",
        "explanation": "Your caption will be created in Swedish language"
    },
    {
        "value": "Korean",
        "label": "Korean",
        "explanation": "Your caption will be created in Korean language"
    },
    {
        "value": "Turkish",
        "label": "Turkish",
        "explanation": "Your caption will be created in Turkish language"
    },
    {
        "value": "Polish",
        "label": "Polish",
        "explanation": "Your caption will be created in Polish language"
    },
    {
        "value": "Vietnamese",
        "label": "Vietnamese",
        "explanation": "Your caption will be created in Vietnamese language"
    }
]


export default languageArray;
