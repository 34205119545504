import React, {useState} from 'react';
import {extractInitialsFromEmail, truncateString} from "../Helpers/helper";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../store/slices/authSlice";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import {DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Button from "@mui/joy/Button";
import {CiLogin} from "react-icons/ci";
import Ellipsis from "../Ellipsis/Ellipsis";

const LogoutView = ({user}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const signOut = () => {
        localStorage.clear();
        dispatch(logout());
        navigate('/login');
    };

    return (
        <>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar
                    variant="outlined"
                    size="md"
                    src={user?.displayPicture ? `${process.env.REACT_APP_IMAGE_URL}${user.c_displayPicture}` : undefined}
                    alt={
                        user?.firstName && user?.lastName ? `${user?.firstName[0].toUpperCase()}${user?.lastName[0].toUpperCase()}` : extractInitialsFromEmail(user.email)
                    }
                >
                </Avatar>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level="title-sm">
                        {
                            truncateString(user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : user.email, 13)
                        }
                    </Typography>
                    <Typography level="body-xs">
                        <Ellipsis character={22}>
                            {user?.email}
                        </Ellipsis>
                    </Typography>
                </Box>
                <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={() => setOpen(true)}
                >
                    <CiLogin size={20}/>
                </IconButton>
            </Box>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog size='sm'>
                    <ModalClose/>
                    <DialogTitle>
                        <Typography level="title-sm">Are You Sure ?</Typography>
                    </DialogTitle>
                    <DialogContent>
                        You want to Log Out?
                    </DialogContent>
                    <Divider/>
                    <DialogActions>
                        <Button size="sm" autoFocus onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={signOut} color="warning" size="sm">
                            Confirm
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    );
};

export default LogoutView;
