import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88vw;
    background-color: #f5f5f5;
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    box-sizing: border-box;
    position: relative;
`;

export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #e0e0e0;
    padding: 1rem 0;
    background-color: #f5f5f5;

    img {
        width: 20px;
        margin: 10px;
    }
`;


export const BodyWrapper = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    max-height: 85vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Table = styled.table`
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 14px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 2rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;    
`;

export const Thead = styled.thead`
    color: white;
    background: linear-gradient(104deg, rgba(84, 40, 154, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(15, 13, 49, 1) 92%);
    padding: 0 16px 0 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
`;

export const Tbody = styled.tbody`
    background-color: #f1f1f1;
`;

export const Tr = styled.tr`
    font-size: 14px;
    background-color: ${({active}) => (active ? "wheat" : null)};
    display: ${({isCollapsed}) => (isCollapsed ? "none" : "table-row")};

    th {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    transition: all 1s ease-in-out;
`;


export const Td = styled.td`
    padding: ${props => props?.padding ? props.padding : "4px;"};
    border-bottom: 1px solid #ddd;
    font-weight: 100;
    text-align: center;

    pre {
        white-space: pre-wrap;
        text-align: left;
        font-size: 12px;
    }

    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
    }

    video {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
    }
`;


export const VideoTd = styled.td`

    padding: 4px;
    border-bottom: 1px solid #ddd;
    font-weight: 100;
    text-align: center;

    pre {
        white-space: pre-wrap;
        margin: 2px;
        text-align: left;
    }


    img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;

        &:hover {
            // scale to 1.5 with a transition
            transform: scale(1.3);
            transition: all 0.3s ease-in-out;
        }
    }

    video {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;

        &:hover {
            // scale to 1.5 with a transition
            transform: scale(1.3);
            transition: all 0.3s ease-in-out;
        }
    }


    ::after {

        content: ${(props) => props.tip === "pending" ? `"Approve / Reject Here"` : null};
        position: absolute;
        top: 80%;
        left: 50%;
        transform: translateX(-50%);
        padding: 4px 4px 4px 4px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        font-size: 10px;
    }

    :hover::after {
        opacity: 1;
        visibility: visible;
    }
`;

export const Buttoni = styled.button`
    border: none;
    color: white;
    padding: 12px 20px 12px 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    background: linear-gradient(104deg, rgba(84, 40, 154, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(15, 13, 49, 1) 92%);
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth transitions for background color, transform, and box-shadow */

    &:hover {
        background: linear-gradient(104deg, rgba(15, 13, 49, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(84, 40, 154, 1) 92%);
        transform: scale(1.05); /* Scale up the button on hover */
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle box-shadow on hover */
    }
`;

export const ButtonLink = styled.a`
    border: none;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 35px;
    cursor: pointer;
    border-radius: 12px;
    background: rgb(84, 40, 154);
    color: #54289a;
`;


export const Button2 = styled.button`
    border: none;
    background: ${({active}) => (active ? "white" : "transparent")};
    cursor: pointer;
    font-size: 18px;
    color: ${({active}) => (active ? "#54289a" : "black")};
    border-bottom: ${({active}) => (active ? "2px solid #54289a" : "none")};
    padding: ${({active}) => (active ? "10px 20px 4px 20px" : "10px")};
    border-top-left-radius: ${({active}) => (active ? "10px" : "none")};
    border-top-right-radius: ${({active}) => (active ? "10px" : "none")};
    font-weight: ${({active}) => (active ? "600" : "300")};
    position: relative;
`;

export const RoundNotification = styled.div`
    background-color: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    position: absolute;
    top: 10px;
    left: -20px;
`;


export const UpperHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
`;

export const UpperHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border-bottom: 1px solid #e0e0e0;
    width: calc(100% - 30px);
    height: 10vh;
    padding: 0 15px;

    h1 {
        font-size: 30px;
        font-weight: 600;
        margin: 0 0 0 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        // show pointer
        cursor: pointer;
    }
`;

export const DownHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    padding: 2rem 0 0px 0;
`;

export const PublishModal = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
`;

export const PublishModalInnerwrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    min-height: 50%;

    img {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
    }

    span {
        font-size: 40px;
        color: black;
        padding: 10px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
`;


export const ImagePreview = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    margin: 10px;

    img {
        max-width: 80%;
        max-height: 80%;
    }

    video {
        max-width: 80%;
        max-height: 80%;
    }
`;


export const PublishModalBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;

    padding: 1rem;
    box-sizing: border-box;

    input {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    textarea {
        width: 100%;
        padding: 12px 20px;
        margin: 8px 0;
    }
`;

export const SocialMedia = styled.div`
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        color: black;
        margin: 3px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    //button {
    //    padding: 12px 20px;
    //    border-radius: 5px;
    //    border: 1px solid #f6f6f6;
    //    color: white;
    //    cursor: pointer;
    //
    //    :hover {
    //        opacity: 0.8;
    //    }
    //}
    //
    //button:first-child {
    //    background: green;
    //}
    //
    //button:last-child {
    //    margin-left: 20px;
    //    background: red;
    //}
`;

export const ChatImagePreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 98%;
`;

export const CloseIcon = styled.span`
    font-size: 40px;
    color: black;
    padding: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
`;


export const LeftSide = styled.div`
    flex: 7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 10px;
    background: #f0eded;
    position: relative;
    height: 95%;
    width: 100%;
`;


export const VideoImageWrapper = styled.div`
    img {
        width: 15vw;
        height: 15vw;
    }
    video {
        max-width: 15vw;
        max-height: 15vw;
    }
`;


export const RightSide = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;
    box-sizing: border-box;
    position: relative;
`;


export const CompareImage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
    }
`;


export const ComparedCard = styled.div`
    width: 45%;
    height: 90%;
    box-sizing: border-box;
    margin: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    p {
        font-size: 14px;
        margin: 10px;

    }

    pre {
        font-size: 14px;
        margin-right: auto;
        height: 100px;
        overflow-y: scroll;
        overflow-x: hidden;
    }


    span {

        margin-left: auto;

        p {
            font-size: 12px;
        }
    }
`;

export const CompareCardDescription = styled.div`
    width: 100%;
    height: 20%;
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background: #e8e8e8;
    padding: 4px;
`;

export const CompareCardImageWrapper = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50%;
    }

    video {
        width: 50%;
        object-fit: cover;
    }
`;


export const Toast = styled.div`
    color: black;
    padding: 10px;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    animation: slideIn 0.5s ease-in-out forwards;

    @keyframes slideIn {
        0% {
            transform: translateY(-100%);
        }
        100% {
            transform: translateY(0);
        }
    }
`;

export const ToastClose = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 28px;
    color: black;
`;

export const ToastCounterCircle = styled.div`
    background-color: red;
    border-radius: 50%;
    width: 20px;
    padding: 4px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    position: absolute;
    top: -10px;
    right: -20px;
`;

export const FloatingSideMenu = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    position: absolute;
    top: 0;
    left: 2%;
    z-index: 10;
`;

export const FloatingSideMenuContent = styled.div`
    position: relative; /* Add this to make positioning the tooltip relative to this element */
    font-size: 19px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 20px 0 0 0;
    background: linear-gradient(145deg, #f3f3f3, #cccccc);
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.08);
    -23px -23px 45px #ffffff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;


    :hover {
        transform: scale(1.1);
        box-shadow: 23px 23px 45px #c1c1c1,
        -23px -23px 45px #ffffff;
    }

    a {
        color: black;
        text-decoration: none;
        font-size: 14px;
        margin: 10px;
    }

    ::after {
        content: ${(props) => `"${props.tip}"`};
        position: absolute;
        //bottom: 100%; /* Position above the element */
        left: 60px; /* Centered horizontally */
        transform: translateX(-50%);
        padding: 4px 4px 4px 4px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        white-space: nowrap;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
        font-size: 10px;
    }

    :hover::after {
        opacity: 1;
        visibility: visible;
    }
`;

export const CaptionBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-radius: 12px;
    box-sizing: border-box;
    width: 90%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 10px;
    padding: 0 50px 0 50px;
    position: relative;
    cursor: pointer;
`;

export const CaptionContainerBox = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the container */
    justify-content: flex-start; /* Start content from the top */
    padding: 10px;
    width: 100%;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto; /* Use auto to show the scrollbar when necessary */
    overflow-x: hidden;
    background-color: white;
    position: relative;

    pre {
        text-align: left;
        font-size: 12px;
        white-space: pre-wrap; /* Ensures text wraps while preserving whitespace */
        word-wrap: break-word; /* Helps break long words if necessary */
        width: 100%; /* Ensure it takes the full width of the container */
        margin: 0; /* Remove default margin */
        padding: 0; /* Ensure no padding is causing cut-off */
        overflow: visible; /* Ensure the pre's content is visible */
        flex-shrink: 0; /* Prevent shrinking to ensure full visibility */
    }

    ::-webkit-scrollbar {
        display: none; /* Hides the scrollbar for a cleaner look */
    }
`;
export const CaptionAiButtonContainer = styled.div`
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-top: 10px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
    }  
`;

export const CaptionAiButton = styled(Buttoni)`
    margin: 10px;
    background: ${({active}) => (active ? "green" : "transparent")};
    color: ${({active}) => (active ? "white" : "black")};
    border: ${({active}) => (active ? "none" : "1px solid #f6f6f6")};
    font-size: 14px;
    box-shadow: ${({active}) => (active ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none")};
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease; /* Add smooth transitions for background color, transform, box-shadow, and color */

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        background: ${({active}) => (active ? "green" : "transparent")};
    }
`;





