import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {AiFillBulb} from 'react-icons/ai';

const DefaultSelect = ({
                           options,
                           selectedValue,
                           onSelect,
                           placeholder,
                           label,
                           isMulti,
                           disabled
                       }) => {
    const [selectedOption, setSelectedOption] = useState(selectedValue);

    useEffect(() => {
        setSelectedOption(selectedValue);
    }, [selectedValue]);

    const handleChange = selectedOption => {
        setSelectedOption(selectedOption);
        onSelect(selectedOption);
    };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: '100px',
            overflowY: 'auto',
        }),
    };

    return (
        <>
            {label ? (
                <label
                    style={{
                        marginTop: '10px',
                        marginBottom: '7px'
                    }}
                >
                    {label}
                </label>
            ) : null}
            <Select
                isDisabled={disabled}
                styles={customStyles}
                value={selectedOption}
                onChange={handleChange}
                options={options}
                placeholder={selectedOption ? selectedOption : placeholder}
                isMulti={isMulti}
            />
            {selectedOption?.explanation ? (
                <>
                    <h5>
                        <AiFillBulb/> : {selectedOption.explanation}
                    </h5>
                </>
            ) : null}
        </>
    );
};

export default DefaultSelect;
