import React from 'react';
import styled, {keyframes} from 'styled-components';

// Keyframes for the rotating animation
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled component for the loader container
const LoaderContainer = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
`;

// Styled component for the loader itself
const Loader = styled.div`
  position: absolute;
  border: 4px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%; /* Circular shape */
  width: 15px;
  height: 15px;
  animation: ${rotate} 2s linear infinite; /* Rotate animation */
`;

const CircularLoader = () => {
    return (
        <LoaderContainer>
            <Loader/>
        </LoaderContainer>
    );
};

export default CircularLoader;
