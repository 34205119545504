import styled from "styled-components";

export const NavBarWrapper = styled.div`
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    background: white;
`;

export const NavBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 5px;


    h1 {
        font-weight: 500;
        font-size: 1.5rem;
        padding: 10px 0 10px 0;
    }
`;


export const BodyContainer = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
`;


export const MainBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;


export const ProfileMenuContainer = styled.div`
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background: #f5f5f5;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    margin: 10px 0 10px 0;
`;

export const ProfileMenuTop = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;


export const ProfileViewCard = styled.div`
    width: 38%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 10px;
`;

export const UserDetailsWrapper = styled.div`
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    p{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e0e0e0;
        padding: 3px 0;
        text {
            max-width: 60%;
            text-align: end;
        }
    }
`


export const PictureView = styled.div`
    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    position: relative;
`;


export const ProfileMenuBottom = styled.div`
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`;


export const ProfileMenuItem = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid #e0e0e0;

    &:hover {
        background: #e0e0e0;

        cursor: pointer;
    }

    p {
        margin-left: 20px;
    }
`;


