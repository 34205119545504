import axios from "axios";
import {toast} from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader,
} from "../../../../Components/UI/modal.style";
import {Buttoni} from "../../board.style";
import {useParams} from "react-router-dom";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {SelectFile} from "../../../../Components/UI/basic.style";
import {isVideo} from "../../../../Components/Helpers/helper";

const CreativeRevision = ({show, close, creativeID}) => {
    const [video, setVideo] = useState(null);
    const params = useParams();
    const [key, setKey] = useState(0);

    const uploadFile = e => {
        if (e.target.files[0].size > 50 * 1024 * 1024) {
            setKey(prevKey => prevKey + 1);
            return toast.error('File size exceeds 50 MB. Please choose a smaller file.');
        }
        setVideo(e.target.files[0])
    }

    useEffect(() => {
        setVideo(null);
    }, [show]);

    const revision = async () => {

        const formData = new FormData();
        formData.append("video", video);
        formData.append('project', params.id);

        toast.loading("Uploading content...");
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}creative/revision/${creativeID._id}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            close();
            setVideo(null);
            toast.dismiss();
            toast.success("Revision uploaded successfully")
        } catch (e) {
            toast.error("Something went wrong");
        }
    };

    return (
        <>
            {show ? (
                <ModalContainer>
                    <Modal>
                        <ModalHeader>
                            <h3 className="modal_header-title">Upload Revision for Post {creativeID.title}</h3>
                            <CloseButton onClick={close}><IoIosCloseCircleOutline/></CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'start',
                                height: '20%',
                                padding: '10px',
                                backgroundColor: 'ivory',
                            }}
                            >
                                <h3>Current Content</h3>

                                {isVideo(creativeID.link) ? (
                                    <video controls>
                                        <source src={creativeID.link} type="video/mp4"/>
                                    </video>
                                ) : (
                                    <img src={creativeID.link} alt={'img'}></img>
                                )}
                            </div>
                            <InputWrapper>
                                <SelectFile
                                    key={key}
                                    type="file"
                                    accept="image/*,video/*"
                                    onChange={uploadFile}
                                />
                            </InputWrapper>
                        </ModalBody>
                        <ModalFooter>
                            <Buttoni onClick={revision}>Upload Revision</Buttoni>
                        </ModalFooter>
                    </Modal>
                </ModalContainer>
            ) : null}
        </>
    );
};

export default CreativeRevision;
