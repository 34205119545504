import * as React from 'react';
import {useEffect, useState} from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {closeSidebar} from "../Helpers/helper";
import {fetchUserData} from "../../store/slices/userSlice";
import axios from "axios";
import {logout} from "../../store/slices/authSlice";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import LogoutView from "../LogoutMenu/logOutView";
import Card from "@mui/joy/Card";
import Stack from "@mui/joy/Stack";
import IconButton from "@mui/joy/IconButton";
import {LinearProgress} from "@mui/joy";
import Button from "@mui/joy/Button";
import {SiWelcometothejungle} from "react-icons/si";
import {AiOutlineBank, AiOutlineProject, AiOutlineSetting} from "react-icons/ai";
import {IoIosNotificationsOutline, IoMdClose} from "react-icons/io";
import {MdContactSupport} from "react-icons/md";
import {IoSearchOutline} from "react-icons/io5";
import ContactUs from "../Contact-us/contact-us";
import UserViewComponent from "../../pages/Settings/Components/user-view-component";

function Toggler({
                     defaultExpanded = false,
                     renderToggle,
                     children,
                 }: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {

    const [open, setOpen] = React.useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({open, setOpen})}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export default function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [open, setOpen] = useState(false)
    const userData = useSelector((state) => state.user);
    const organisationData = useSelector((state) => state.organisation);
    const projectData = useSelector((state) => state.project);
    const [userProfiles, setUserProfile] = React.useState(false);


    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);

    const calculateCompletion = () => {
        let completedFields = 0;
        if (userData.firstName) completedFields++;
        if (userData.lastName) completedFields++;
        if (userData.aboutProfile) completedFields++;

        return (completedFields / 3) * 100;
    };
    const completionPercentage = calculateCompletion();

    const verifyToken = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}auth/verify-token`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.status === 200;

        } catch (error) {

            return error?.response?.data?.statusCode !== 401;

        }
    };

    useEffect(() => {
        if (['/menu', '/', '/menu/'].includes(location.pathname)) {
            navigate('/menu/dashboard');
        }
    }, [location?.pathname, navigate]);


    const checkToken = async () => {
        const isValid = await verifyToken();
        if (!isValid) {
            navigate('/login');
            dispatch(logout());
            localStorage.clear();
        }
    };


    useEffect(() => {
        checkToken()
    }, [navigate, dispatch]);

    const handleNavigate = (value) => {
        navigate('/menu/' + value);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
            <Sheet
                className="Sidebar"
                sx={{
                    position: {xs: 'fixed', md: 'sticky'},
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                        md: 'none',
                    },
                    transition: 'transform 0.4s, width 0.4s',
                    zIndex: 10,
                    height: '100dvh',
                    width: 'var(--Sidebar-width)',
                    top: 0,
                    p: 2,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <GlobalStyles
                    styles={(theme) => ({
                        ':root': {
                            '--Sidebar-width': '220px',
                            [theme.breakpoints.up('lg')]: {
                                '--Sidebar-width': '240px',
                            },
                        },
                    })}
                />
                <Box
                    className="Sidebar-overlay"
                    sx={{
                        position: 'fixed',
                        zIndex: 9998,
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        opacity: 'var(--SideNavigation-slideIn)',
                        backgroundColor: 'var(--joy-palette-background-backdrop)',
                        transition: 'opacity 0.4s',
                        transform: {
                            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                            lg: 'translateX(-100%)',
                        },
                    }}
                    onClick={() => closeSidebar()}
                />
                <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                    <IconButton variant="soft" color="primary" size="sm" sx={{
                        borderRadius: '50%',
                    }}>
                        <img style={{
                            width: '30px',
                            borderRadius: '50%'
                        }} src='/Fennel1.png' alt={'img'}/>
                    </IconButton>
                    <Typography level="title-lg">Sounf Dashboard</Typography>
                    {/*<ColorSchemeToggle sx={{ml: "auto"}}/>*/}
                </Box>
                <Input size="sm" startDecorator={<IoSearchOutline/>} placeholder="Search"/>
                <Box
                    sx={{
                        minHeight: 0,
                        overflow: 'hidden auto',
                        flexGrow: 0.92,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${listItemButtonClasses.root}`]: {
                            gap: 1.5,
                        },
                    }}
                >
                    <List
                        size="sm"
                        sx={{
                            gap: 1,
                            '--List-nestedInsetStart': '30px',
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        }}
                    >
                        <ListItem onClick={() => handleNavigate('dashboard')} sx={{
                            backgroundColor: window.location.pathname.includes('/menu/dashboard') ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        }}>
                            <ListItemButton>
                                <SiWelcometothejungle/>
                                <ListItemContent>
                                    <Typography level="title-sm">Welcome</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>

                        <ListItem onClick={() => handleNavigate('organisation')} sx={{
                            backgroundColor: window.location.pathname.includes('/menu/organisation') ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        }}>
                            <ListItemButton>
                                <AiOutlineBank/>
                                <ListItemContent>
                                    <Typography level="title-sm">Organisation</Typography>
                                </ListItemContent>
                                {/*{*/}
                                {/*    organisationData?.data?.length > 0 &&*/}
                                {/*    <Chip size="sm" color="primary" variant="solid">*/}
                                {/*        {organisationData.data.length}*/}
                                {/*    </Chip>*/}
                                {/*}*/}
                            </ListItemButton>
                        </ListItem>


                        <ListItem onClick={() => handleNavigate('project/:id')} sx={{
                            backgroundColor: window.location.pathname.includes('/menu/project') ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        }}>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                            >
                                <AiOutlineProject/>
                                <ListItemContent>
                                    <Typography level="title-sm">Project</Typography>
                                </ListItemContent>
                                {/*{*/}
                                {/*    projectData?.data?.length > 0 &&*/}
                                {/*    <Chip size="sm" color="primary" variant="solid">*/}
                                {/*        {projectData.data.length}*/}
                                {/*    </Chip>*/}
                                {/*}*/}
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => handleNavigate('notifications')} sx={{
                            backgroundColor: window.location.pathname.includes('/menu/notifications') ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        }}>
                            <ListItemButton
                                role="menuitem"
                                component="a"
                            >
                                <IoIosNotificationsOutline/>
                                <ListItemContent>
                                    <Typography level="title-sm">Notifications</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        {/*<ListItem nested>*/}
                        {/*    <Toggler*/}
                        {/*        renderToggle={({open, setOpen}) => (*/}
                        {/*            <ListItemButton onClick={() => setOpen(!open)}>*/}
                        {/*                <AssignmentRoundedIcon/>*/}
                        {/*                <ListItemContent>*/}
                        {/*                    <Typography level="title-sm">Tasks</Typography>*/}
                        {/*                </ListItemContent>*/}
                        {/*                <KeyboardArrowDownIcon*/}
                        {/*                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}*/}
                        {/*                />*/}
                        {/*            </ListItemButton>*/}
                        {/*        )}*/}
                        {/*    >*/}
                        {/*        <List sx={{gap: 0.5}}>*/}
                        {/*            <ListItem sx={{mt: 0.5}}>*/}
                        {/*                <ListItemButton>All tasks</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemButton>Backlog</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemButton>In progress</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemButton>Done</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*        </List>*/}
                        {/*    </Toggler>*/}
                        {/*</ListItem>*/}
                        {/*<ListItem nested>*/}
                        {/*    <Toggler*/}
                        {/*        defaultExpanded*/}
                        {/*        renderToggle={({open, setOpen}) => (*/}
                        {/*            <ListItemButton onClick={() => setOpen(!open)}>*/}
                        {/*                <GroupRoundedIcon/>*/}
                        {/*                <ListItemContent>*/}
                        {/*                    <Typography level="title-sm">Users</Typography>*/}
                        {/*                </ListItemContent>*/}
                        {/*                <KeyboardArrowDownIcon*/}
                        {/*                    sx={{transform: open ? 'rotate(180deg)' : 'none'}}*/}
                        {/*                />*/}
                        {/*            </ListItemButton>*/}
                        {/*        )}*/}
                        {/*    >*/}
                        {/*        <List sx={{gap: 0.5}}>*/}
                        {/*            <ListItem sx={{mt: 0.5}}>*/}
                        {/*                <ListItemButton selected>My profile</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemButton>Create a new user</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*            <ListItem>*/}
                        {/*                <ListItemButton>Roles & permission</ListItemButton>*/}
                        {/*            </ListItem>*/}
                        {/*        </List>*/}
                        {/*    </Toggler>*/}
                        {/*</ListItem>*/}
                    </List>
                    <List
                        size="sm"
                        sx={{
                            mt: 'auto',
                            flexGrow: 0,
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                            '--List-gap': '8px',
                            mb: 2,
                        }}
                    >
                        <ListItem onClick={() => setOpen(true)}>
                            <ListItemButton>
                                <MdContactSupport/>
                                Contact Us
                            </ListItemButton>
                        </ListItem>
                        <ListItem onClick={() => handleNavigate('settings')} sx={{
                            backgroundColor: window.location.pathname.includes('/menu/settings') ? 'rgba(0, 0, 0, 0.08)' : 'transparent',
                        }}>
                            <ListItemButton>
                                <AiOutlineSetting/>
                                Settings
                            </ListItemButton>
                        </ListItem>
                    </List>
                    {completionPercentage < 100 &&
                        <Card
                            invertedColors
                            variant="soft"
                            color="warning"
                            size="sm"
                            sx={{boxShadow: "none"}}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography level="title-sm">Complete Your Profile</Typography>
                                <IconButton size="sm">
                                    <IoMdClose/>
                                </IconButton>
                            </Stack>
                            <Typography level="body-xs">
                                Your profile is {Math.round(completionPercentage)}% complete. Complete your profile to
                                get the most out of your account.
                            </Typography>
                            <LinearProgress
                                variant="outlined"
                                value={Math.round(completionPercentage)}
                                determinate
                                sx={{my: 1}}
                            />
                            <Button onClick={() => handleNavigate('settings')} size="sm" variant="solid">
                                Complete Profile
                            </Button>
                        </Card>
                    }
                </Box>
                <Divider/>
                <LogoutView user={
                    userData
                }/>
                {
                    userProfiles ? <UserViewComponent user={userData} close={
                        () => {
                            setUserProfile(false)
                        }
                    }

                                                      allowEdit={true}
                    /> : null
                }
            </Sheet>
            <ContactUs open={open} close={(val) => setOpen(val)}/>
            <Outlet/>
        </div>
    );
}
