import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    userName: localStorage.getItem('userName') || null,
    token: localStorage.getItem('token') || null,
    isAuthenticated: !!localStorage.getItem('token'),
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            state.userName = action.payload.userName;
            state.token = action.payload.token;
            state.isAuthenticated = true;
        },
        logout(state) {
            state.userName = null;
            state.token = null;
            state.isAuthenticated = false;
        },
    }
});

export const {login, logout} = authSlice.actions;

export default authSlice.reducer;
