import React from 'react';
import styled from 'styled-components';

const ConfirmationContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const ConfirmationBox = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  border: 1px solid #d4d4d4;
  position: relative;
  width: 350px;
`;

const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: #777;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  background-color: ${(props) => props.color || '#007AFF'};
  color: ${(props) => (props.textColor ? props.textColor : '#007AFF')};
  font-size: 16px;
  margin: 2px 5px;
`;


const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;


const Input = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-bottom: 15px
`;

const AlertModal = ({title, subtitle, onConfirm, onCancel, additionalButtons, inputs}) => {
    return (
        <ConfirmationContainer>
            <ConfirmationBox>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <InputsContainer>
                    {inputs &&
                        inputs.map((input, index) => (
                            <Input key={index} type={input.type || 'text'} placeholder={input.placeholder}/>
                        ))}
                </InputsContainer>
                <ButtonContainer>
                    {additionalButtons &&
                        additionalButtons.map((button, index) => (
                            <Button
                                key={index}
                                color={button.color}
                                onClick={button.onClick}
                            >
                                {button.label}
                            </Button>
                        ))}
                    <Button onClick={onCancel} color="#007AFF" textColor="#fff">
                        Cancel
                    </Button>
                    <Button onClick={onConfirm}  color="#f5f5f5" textColor="red">
                        Confirm
                    </Button>
                </ButtonContainer>
            </ConfirmationBox>
        </ConfirmationContainer>
    );
};

export default AlertModal;
