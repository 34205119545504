import React, {useEffect} from "react";
import "./notFound.css";


const NotFound = () => {


        const handleKeyDown = (event) => {
            event.preventDefault();
            window.history.back();
        }

        // if any  key is pressed, go back to the previous page

        useEffect(() => {
            document.addEventListener("keydown", handleKeyDown);
            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }, []);

        return (
            <div className="wrapper">
                <div className="notfound">
                    <div className="centered"><span
                        className="inverted">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>&nbsp;</div>
                    <div className="centered"><span className="inverted">&nbsp;4&nbsp;0&nbsp;4&nbsp;</span><span
                        className="shadow">&nbsp;</span></div>
                    <div className="centered"><span
                        className="inverted">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        className="shadow">&nbsp;</span></div>
                    <div className="centered">&nbsp;<span
                        className="shadow">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></div>
                    <div className="row">&nbsp;</div>
                    <div className="row">A fatal exception 404 has occurred at C0DE:$UBH0& in 0xC0DEBA5E.</div>
                    <div className="row">The current request will be terminated.</div>
                    <div className="row">&nbsp;</div>
                    <div className="row">* Press any key to return to the previous page.</div>
                    <div className="row">* Press CTRL+ALT+DEL to restart your computer. You will</div>
                    <div className="row">&nbsp;&nbsp;lose any unsaved information in all applications.</div>
                    <div className="row">&nbsp;</div>
                    <div className="centered">Press any key to continue...<span className="blink">&#9608;</span></div>
                </div>
            </div>
        );
    }
;

export default NotFound;
