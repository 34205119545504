// src/pages/Dashboard.js

import React, { useEffect, useState } from "react";
import { ScreenWrapper } from "../../Components/UI/basic.style";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchProjectsData } from "../../store/slices/projectSlice";
import { fetchOrganisationData } from "../../store/slices/organisationSlice";
import CreateOrg from "../Organisation/Create-org/create-org";
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import Divider from "@mui/joy/Divider";
import { FaBuilding, FaPlus, FaRocket, FaTasks } from 'react-icons/fa';
import { keyframes } from "@emotion/react";
import Button from "@mui/joy/Button";

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-10px);
  }
`;

// StyledButton component with fixed width
const StyledButton = (props) => (
    <Button
        size='lg'
        sx={{
            width: '100%', // Ensures the button takes the full width of its container
            maxWidth: 400, // Sets a maximum width for the button
            animation: `${slideIn} 2s ease-out`,
            // Add any other consistent styles here
        }}
        {...props}
    />
);

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);

    const Toggle = () => setModal(!modal);

    useEffect(() => {
        dispatch(fetchOrganisationData());
        dispatch(fetchProjectsData());
    }, [dispatch, modal]);

    return (
        <>
            <ScreenWrapper
                style={{
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundImage: 'url("/path/to/background-image.jpg")',
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    animation: `${fadeIn} 2s ease-out`,
                }}
            >

                <Card
                    variant="outlined"
                    sx={{
                        maxWidth: 600,
                        margin: 'auto',
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: 3,
                        boxShadow: 3,
                        padding: 3,
                        animation: `${slideIn} 2s ease-out`,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2, // Reduced gap for better spacing
                    }}
                >
                    <CardContent>
                        <Typography
                            level="h2"
                            fontWeight="lg"
                            textAlign="center"
                            color="primary"
                            mb={1} // Reduced margin-bottom for a tighter layout
                            sx={{animation: `${bounce} 2s infinite`}}
                        >
                            🎉 Welcome to Sounf! 🎉
                        </Typography>
                        <Divider sx={{marginY: 1}}/> {/* Reduced margin for a tighter layout */}
                        <Box>
                            <Typography
                                level="body1"
                                textAlign="center"
                                mb={1} // Reduced margin-bottom for better spacing
                            >
                                Thank you for choosing Sounf, your ultimate social media marketing tool! 🚀
                            </Typography>
                            <Typography
                                level="body1"
                                textAlign="center"
                            >
                                We’re thrilled to have you onboard. This application is in beta, so if you find any
                                issues, report them to <Typography fontWeight="lg"
                                                                   display="inline"
                                                                   style={{cursor: 'pointer'}}
                                                                   onClick={() => window.open('mailto:tech@noisivconsulting.com')}
                            >tech@noisivconsulting.com</Typography> to
                                get rewarded. 💡
                            </Typography>
                        </Box>
                    </CardContent>
                    <CardActions
                        sx={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: 2,
                            width: '100%',
                        }}
                    >
                        <StyledButton
                            onClick={() => navigate(`/menu/organisation`)}
                            startDecorator={<FaRocket />}
                        >
                            Let's Start
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            onClick={() => navigate(`/menu/organisation`)}
                            startDecorator={<FaBuilding />}
                        >
                            View Your Organizations
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            onClick={Toggle}
                            startDecorator={<FaPlus />}
                        >
                            Create a New Organization
                        </StyledButton>
                        <StyledButton
                            variant="outlined"
                            onClick={() => navigate(`/menu/project/:id`)}
                            startDecorator={<FaTasks />}
                        >
                            View All Projects
                        </StyledButton>
                    </CardActions>
                </Card>
            </ScreenWrapper>
            <CreateOrg show={modal} close={Toggle} />
        </>
    );
};

export default Dashboard;
