import React, {useEffect, useState} from "react";
import {BreadCrumbsHolder, ScreenWrapper} from "../../Components/UI/basic.style";
import {BodyContainer, Card, CardNameHolder, MainBody, Partition, PartitionBody,} from "./organisation.style";
import {useNavigate} from "react-router-dom";
import CreateOrg from "./Create-org/create-org";
import {useDispatch, useSelector} from "react-redux";
import {fetchProjectsData} from "../../store/slices/projectSlice";
import {fetchOrganisationData} from "../../store/slices/organisationSlice";
import {IoMdAdd} from "react-icons/io";
import {Toaster} from "react-hot-toast";
import {FaChevronRight} from "react-icons/fa";
import Marquee from "../../Components/Marquee/marquee";
import CardSkeletonLoader from "../../Components/Loaders/Card-Skeleton-Loader";
import {PartitionTitle} from "../Projects/project-view-style";
import {CiViewTable} from "react-icons/ci";
import {PiCardsLight} from "react-icons/pi";
import Segment from "../../Components/segment/segment";
import SearchBar from "../../Components/Search-Bar/search-bar";
import {Avatar, Breadcrumbs, Button, Table, Typography} from "@mui/joy";
import {IoAdd} from "react-icons/io5";
import Ellipsis from "../../Components/Ellipsis/Ellipsis";
import {AiOutlineLock, AiOutlineProject} from "react-icons/ai";
import Link from "@mui/joy/Link";
import {BiHome} from "react-icons/bi";

const Organisation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const organisation = useSelector((state) => state.organisation.data);
    const Toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [viewChange, setViewChange] = useState(JSON.parse(localStorage.getItem('organisationView')) ?? false);
    const [searchTerm, setSearchTerm] = useState('');


    const filteredData = organisation?.filter(item =>
        Object.values(item).some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
    )

    function toggleTableView(val) {
        localStorage.setItem('organisationView', val)
        setViewChange(val)
    }

    useEffect(() => {
        dispatch(fetchOrganisationData()).then(() => setLoading(false));
        dispatch(fetchProjectsData());
    }, [dispatch, modal]);

    return (
        <>
        <ScreenWrapper>
            <BodyContainer>
                <MainBody>
                    <BreadCrumbsHolder>
                        <Breadcrumbs separator="›" size='sm'>
                            <Link color="primary" href="/">
                                <BiHome/>
                            </Link>
                            <Typography>
                                <Typography>Organisations</Typography>
                            </Typography>
                    </Breadcrumbs>
                </BreadCrumbsHolder>
                <Partition>
                    <PartitionTitle>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography
                                level="title-lg">Organisations{" "}{organisation.length > 0 ? `(${organisation.length})` : null}</Typography>
                            <SearchBar value={val => setSearchTerm(val)} searchTerm={searchTerm}/>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Segment style={{marginRight: '10px'}} values={[<CiViewTable/>, <PiCardsLight/>]}
                                     onSegmentChange={val => val === 1 ? toggleTableView(true) : toggleTableView(false)}
                                     selectedValue={viewChange ? 1 : 0}/>
                        </div>
                    </PartitionTitle>
                    {
                        !viewChange && <div
                            style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end'}}>
                            <Button
                                variant="solid"
                                color="primary"
                                onClick={Toggle}
                                startDecorator={<IoAdd/>}
                            >Add Organisation</Button>
                        </div>
                    }
                    {viewChange && <PartitionBody>
                        <Card
                            onClick={Toggle}>
                            <CardNameHolder
                                style={{
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <p style={{
                                    color: '#6e6e6e',
                                    flexDirection: 'row',
                                    width: '100%',
                                    alignItems: 'center'
                                }}><IoMdAdd/> Create Organisation</p>
                            </CardNameHolder>
                        </Card>
                        {
                            loading && <CardSkeletonLoader width={"250px"} heights={"100px"} number={3}/>
                        }


                        {!loading && filteredData?.map((item, index) => {
                            return (
                                <Card
                                    backgroundImage={item?.organisationType}
                                    backgroundLogo={item?.name}
                                    onClick={() => {
                                        navigate(`/menu/project/${item?._id}`);
                                    }}
                                    key={index}
                                >
                                    <CardNameHolder>
                                        <img src={`${process.env.REACT_APP_IMAGE_URL}${
                                            item?.c_organistionLogo ? item.c_organistionLogo : item.organisationLogo
                                        }`} alt={'img'}/>
                                        <p>
                                            {item.name.length >= 14 ?
                                                <Marquee>{item?.name}</Marquee> : item?.name}
                                            <small
                                                style={{
                                                    color: '#c2c2c2',
                                                    fontWeight: '400'
                                                }}> {+item?.projects > 1 ? `${item?.projects} projects` : `${item?.projects} project`}
                                            </small></p>
                                    </CardNameHolder>
                                    <FaChevronRight style={{
                                        fontSize: "10px",
                                        position: "absolute",
                                        top: "45%",
                                        right: "10px",
                                    }}/>
                                </Card>
                            );
                        })}
                    </PartitionBody>}
                </Partition>
                <Partition>
                    {
                        !viewChange && <PartitionBody style={{padding: '0'}}>
                            <Table
                                variant="soft"
                                size="md"
                                stickyHeader={true}
                            >
                                <thead>
                                <tr>
                                    <th style={{width: '5%'}}>S.No.</th>
                                    <th></th>
                                    <th>Organisation</th>
                                    <th>Projects</th>
                                    <th style={{width: '30%'}}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {!loading &&
                                    filteredData?.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {item?.name ?
                                                        <Avatar
                                                            src={`${process.env.REACT_APP_IMAGE_URL}${
                                                                item?.c_organistionLogo ? item.c_organistionLogo : item?.organisationLogo
                                                            }`}
                                                            size="lg"
                                                            alt="img"
                                                        /> :
                                                        <Avatar size="lg" variant={"rounded"}>
                                                            {item.name.charAt(0).toUpperCase()}
                                                        </Avatar>
                                                    }
                                                </td>
                                                <td>
                                                    <Ellipsis>
                                                        {item.name}
                                                    </Ellipsis>
                                                </td>
                                                <td>{item?.projects} Projects</td>
                                                <td>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Button variant="outlined" color="primary" size={"sm"}
                                                                startDecorator={<AiOutlineLock/>}
                                                                onClick={() => {
                                                                    navigate(`/menu/organisation/${item._id}/secrets`);
                                                                }}
                                                        >
                                                            <Ellipsis>
                                                                Access Vault of {item.name}
                                                            </Ellipsis>
                                                        </Button>
                                                        &nbsp;
                                                        <Button variant="outlined" color="primary" size={"sm"}
                                                                startDecorator={<AiOutlineProject/>}
                                                                onClick={() => {
                                                                    navigate(`/menu/project/${item._id}`);
                                                                }}
                                                        >
                                                            <Ellipsis>
                                                                View Projects
                                                            </Ellipsis>
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        </PartitionBody>
                    }
                </Partition>
            </MainBody>
        </BodyContainer>
        </ScreenWrapper>
    <CreateOrg show={modal} close={Toggle}/>
    <Toaster/>
</>

)
    ;
};

export default Organisation;
