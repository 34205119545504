import React, {useEffect, useState} from "react";
import {
    BodyContainer,
    MainBody,
    NavBar,
    NavBarWrapper,
    PictureView,
    ProfileMenuBottom,
    ProfileMenuContainer,
    ProfileMenuItem,
    ProfileMenuTop,
    ProfileViewCard,
    UserDetailsWrapper
} from "./view-setting-style";
import {BreadCrumbsHolder, ScreenWrapper} from "../../Components/UI/basic.style";
import {extractInitialsFromEmail} from "../../Components/Helpers/helper";
import api from "../../api";
import {toast} from "react-hot-toast";
import {ProfileIconContainer, ProfileIconText} from "../../Components/LogoutMenu/logoutMenu.style";
import HoverMenu from "../../Components/Hover-Menu/hover-menu";
import {logout} from "../../store/slices/authSlice";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import UserViewComponent from "./Components/user-view-component";
import {FaCamera} from "react-icons/fa";
import Typography from "@mui/joy/Typography";
import ContactUs from "../../Components/Contact-us/contact-us";
import {Avatar, Breadcrumbs} from "@mui/joy";
import Link from "@mui/joy/Link";
import {BiHome} from "react-icons/bi";

const ViewSettings = () => {


    const [user, setUser] = React.useState({});
    const [userProfiles, setUserProfile] = React.useState(false)
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const handleProfilePictureUpdate = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.style.display = 'none';

        fileInput.onchange = async (e) => {
            const file = fileInput.files?.[0];

            const formData = new FormData();
            formData.append('video', file);


            try {
                const res = await api.updateProfilePicture(formData, user?._id);

                setUser((prev) => ({
                    ...prev,
                    displayPicture: res.data.displayPicture,
                    c_displayPicture: res.data.c_displayPicture
                }));

                toast.success('Profile picture updated successfully');
            } catch (error) {
                toast.error(error.response?.data?.message || 'An error occurred');
            }
        };

        fileInput.click();
    };
    const handleProfilePictureDelete = async () => {
        try {
            await api.updateProfilePicture({
                removeImageTrue: true
            }, user?._id);
            setUser((prev) => ({...prev, displayPicture: null, c_displayPicture: null}));
            toast.success('Profile picture removed successfully');
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };

    useEffect(() => {
        api.getCurrentUser().then((res) => {
            setUser(res.data);
        }).catch((error) => {
            toast.error(error.response.data.message);
        });
    }, []);


    const Initials = (
        initials?: string,
    ) => <ProfileIconContainer>
        <ProfileIconText>
            {extractInitialsFromEmail(initials)
            }</ProfileIconText>
    </ProfileIconContainer>


    const signOut = () => {
        localStorage.clear()
        dispatch(logout())
        navigate('/login')
    }

    const showUserProfile = () => {
        setUserProfile(true)
    }

    return (
        <>
            <ScreenWrapper>
                <BreadCrumbsHolder>
                    <Breadcrumbs separator="›" size='sm'>
                        <Link color="primary" href="/">
                            <BiHome/>
                        </Link>
                        <Typography>
                            <Typography>
                                Settings
                            </Typography>
                        </Typography>
                    </Breadcrumbs>
                </BreadCrumbsHolder>
                <NavBarWrapper>
                    <NavBar>
                        <Typography level='title-lg'>Settings</Typography>
                    </NavBar>
                </NavBarWrapper>
                <BodyContainer>
                    <MainBody>
                        <ProfileMenuContainer>
                            <ProfileMenuTop>
                                <ProfileViewCard>
                                    <PictureView>
                                        <div style={{
                                            position: 'absolute',
                                            top: '58px',
                                            left: '55px'
                                        }}>
                                            <HoverMenu options={[
                                                {
                                                    label: 'Change Profile Picture',
                                                    onClick: () => {
                                                        handleProfilePictureUpdate();
                                                    }

                                                },
                                                {
                                                    label: 'Remove Profile Picture',
                                                    onClick: () => {
                                                        handleProfilePictureDelete();
                                                    }

                                                },
                                            ]} iconSize={20} top="14px" left="10px"
                                                       width="200px"
                                                       icon={<FaCamera onClick={() =>
                                                           handleProfilePictureUpdate()}/>}
                                            />
                                        </div>
                                        {
                                            user?.displayPicture ?
                                                <Avatar sx={{width: 72, height: 72}}
                                                        variant="outlined"
                                                        alt={
                                                            user?.firstName ? user?.firstName : user?.email
                                                        }
                                                        src={process.env.REACT_APP_IMAGE_URL + user?.c_displayPicture}
                                                /> :
                                                <Avatar sx={{width: 72, height: 72}} alt={user?.firstName} variant="outlined" color="primary">
                                                    {Initials(user?.email)}
                                                </Avatar>
                                        }
                                    </PictureView>
                                    {
                                        user?.firstName && user.lastName &&
                                        <p style={{color: 'rgb(19, 21, 144)', textAlign: 'center'}}>
                                            <b>{user?.firstName + ' '} {user?.lastName}</b></p>
                                    }
                                    <UserDetailsWrapper>
                                        {
                                            user?.firstName && user.lastName &&
                                            <Typography level='title-md'>
                                                Name: {user?.firstName + ' '} {user?.lastName}
                                            </Typography>
                                        }
                                        {
                                            user?.email &&
                                            <Typography level='title-md'>Email: {user?.email}</Typography>
                                        }
                                        {
                                            user?.mobileNumber &&
                                            <Typography level='title-md'>Phone: {user?.mobileNumber}</Typography>
                                        }
                                        {
                                            user?.aboutProfile &&
                                            <Typography level='title-md'>About: {user?.aboutProfile}</Typography>
                                        }
                                    </UserDetailsWrapper>
                                </ProfileViewCard>
                            </ProfileMenuTop>
                            <ProfileMenuBottom>
                                <ProfileMenuItem onClick={() => {
                                    showUserProfile()
                                }}>
                                    <p>Account</p>
                                </ProfileMenuItem>
                                <ProfileMenuItem
                                    onClick={() => {
                                        navigate('/menu/notifications')
                                    }}
                                >
                                    <p>Notifications</p>
                                </ProfileMenuItem>
                                <ProfileMenuItem onClick={() => {
                                    window.open('https://noisivconsulting.com/privacy-policy', '_blank');
                                }}>
                                    <p>Privacy Policy</p>
                                </ProfileMenuItem>

                                <ProfileMenuItem onClick={() => {
                                    setOpen(true)
                                }}>
                                    <p>Contact Us</p>
                                </ProfileMenuItem>
                                <ProfileMenuItem onClick={() => {
                                    window.open('https://noisivconsulting.com/terms-of-use/', '_blank');
                                }}>
                                    <p>
                                        Terms of Use
                                    </p>
                                </ProfileMenuItem>
                                <ProfileMenuItem onClick={() => {
                                    signOut()
                                }}>
                                    <p>Logout</p>
                                </ProfileMenuItem>
                            </ProfileMenuBottom>
                        </ProfileMenuContainer>
                    </MainBody>
                </BodyContainer>
            </ScreenWrapper>

            {
                userProfiles ? <UserViewComponent user={user} close={
                    () => {
                        setUserProfile(false)
                    }
                }

                                                  allowEdit={true}
                                                  changeUser={(data) => {
                                                      setUser(data)
                                                  }}
                /> : null
            }

            <ContactUs open={open} close={(val) => setOpen(val)}/>
        </>
    )
        ;
};

export default ViewSettings;
