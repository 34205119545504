import {configureStore} from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import projectReducer from './slices/projectSlice';
import organisation from './slices/organisationSlice';
import userSlice from "./slices/userSlice";


const store = configureStore({
    reducer: {
        auth: authReducer,
        project: projectReducer,
        organisation: organisation,
        user: userSlice,
    },
});


export default store;
