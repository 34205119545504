import styled from "styled-components";
import CircularLoader from "../../Loaders/Circular-Loader";

const Button = styled.button`
  display: flex;
  border: none;
  color: white;
  padding: 12px 20px 12px 20px;
  text-align: justify;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  background: linear-gradient(104deg, rgba(84, 40, 154, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(15, 13, 49, 1) 92%);
  transition: background 0.3s ease, transform 0.3 ease, box-shadow 0.3s ease; /* Add smooth transitions for background color, transform, and box-shadow */
  
  &:hover {
    background: linear-gradient(104deg, rgba(15, 13, 49, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(84, 40, 154, 1) 92%);
    transform: scale(1.05); /* Scale up the button on hover */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle box-shadow on hover */
  }
`;


const PrimaryButton = ({children, onClick, loading}) => {
    return (
        <Button onClick={onClick} disabled={loading}>{loading ? null : children}
            {loading && <CircularLoader />}
        </Button>
    );
};


export default PrimaryButton;
