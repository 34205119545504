import React, {useEffect} from 'react';

import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../../../Components/custome-tags/TextInput/textInput";
import {Buttoni} from "../../Boards/board.style";
import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader
} from "../../../Components/UI/modal.style";
import api from "../../../api";
import {toast} from "react-hot-toast";
import DefaultSelect from "../../../Components/default-select/default-select";
import SocialMediaArray from "../../../constants/socialPlatform";
import postType from "../../../constants/postType";
import axios from "axios";


// ... (import statements remain the same)

const PostForm = ({show, close, projectId, calendarObject, onClose, existingPost}) => {
        const [title, setTitle] = React.useState("");
        const [hashtags, setHashtags] = React.useState("");
        const [description, setDescription] = React.useState("");
        const [buttonLoading, setButtonLoading] = React.useState(false);
        const [sme_platforms, setSmePlatforms] = React.useState([]);
        const [content, setContent] = React.useState("");
        let [contentType, setContentType] = React.useState("");
        const [creativeId, setCreativeId] = React.useState("");


        const [creatives, setCreatives] = React.useState([]);


        let holder = ""

        sme_platforms.map((item) => {
            holder = holder + item.value + ","
        })


        useEffect(() => {
            if (existingPost) {
                setTitle(existingPost.title)
                setDescription(existingPost.description)
                setHashtags(existingPost.has_tags)
                setContent(existingPost.content)
                setCreativeId(existingPost.creatives)
            } else {
                setTitle("")
                setDescription("")
                setHashtags("")
                setContent("")
                setCreativeId([])
            }
        }, [existingPost])


        useEffect(() => {
            fetchCreatives();
        }, [projectId])


        const fetchCreatives = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}creative/${projectId}?approved=all`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                if (response.data.creatives) {
                    setCreatives(response.data.creatives);
                } else {
                    setCreatives([]);
                }


            } catch (error) {
                toast.error("Something went wrong");
            }

        }

        const editPost = async () => {


            setButtonLoading(true);
            const data = {
                title: title,
                description: description,
                has_tags: hashtags,
                calander_id: calendarObject._id,
                project_id: projectId,
                sme_platforms: holder,
                content_type: contentType,
                content: content,
                creatives: creatives
            };

            const result = await api.updatePost(existingPost._id, data);

            if (result.status === 200) {
                setButtonLoading(false);
                toast.success("Post Updated Successfully");
                close();
                onClose(result);
            } else {
                toast.error(result.data.message);
                setButtonLoading(false);
            }

        }
        const cretePost = async () => {

            console.log(creativeId);
            console.log(typeof creativeId);

            let creativee = [];

            if (typeof creativeId === "object") {
                creativee.push(creativeId.value);
            }


            setButtonLoading(true);
            const data = {
                title: title,
                description: description,
                has_tags: hashtags,
                calander_id: calendarObject._id,
                project_id: projectId,
                sme_platforms: holder,
                content_type: contentType,
                content: content,
                creatives: creativee
            };

            const result = await api.createPostWithCalendarIdProjectId(data);

            console.log(result);

            if (result.status === 201) {
                setButtonLoading(false);
                toast.success("Post Added Successfully");
                close();
                onClose(result);
            } else {
                toast.error(result.data.message);
                setButtonLoading(false);
            }

        };

        const handleKeyDown = (event) => {
            if (event.key === "Enter") {
                event.preventDefault(); // Prevent the default behavior of the Enter key (form submission)
                cretePost();
            }
        };

        return (
            <>
                {show ? (
                    <ModalContainer onKeyDown={handleKeyDown} tabIndex="0">
                        <Modal>
                            <ModalHeader>
                                <h2 className="modal_header-title">
                                    Add Post to {""}
                                    {
                                        new Date(calendarObject.c_date).toLocaleDateString('en-US', {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric'
                                        })
                                    }
                                </h2>
                                <CloseButton onClick={() => {
                                    close()
                                }}>
                                    <IoIosCloseCircleOutline/>
                                </CloseButton>
                            </ModalHeader>
                            <ModalBody>
                                <InputWrapper>
                                    <TextInput type='text' placeholder='Christmas Festival'
                                               label="Title of the Post (Required)"
                                               hidePlaceOnHover={true}
                                               value={title}
                                               onChange={(e) => {
                                                   setTitle(e.target.value)
                                               }}/>


                                    <TextInput type='textarea'
                                               placeholder='Post for Christmas Festival'
                                               hidePlaceOnHover={true}
                                               label="Enter the Description (Optional)"
                                               value={description}
                                               onChange={(e) => {
                                                   setDescription(e.target.value)
                                               }}/>


                                    <TextInput type='textarea'
                                               placeholder='Welcome to the 2024, Explore the new world with us #newyear'
                                               hidePlaceOnHover={true}
                                               label="Enter Content (Optional)"
                                               value={content}
                                               onChange={(e) => {
                                                   setContent(e.target.value)
                                               }}/>


                                    <TextInput type='text'
                                               placeholder='#hashtag1 #hashtag2 #hashtag3'
                                               hidePlaceOnHover={true}
                                               label="Enter All the Hashtags (Optional)"
                                               value={hashtags}
                                               onChange={(e) => {
                                                   setHashtags(e.target.value)
                                               }}/>


                                    <DefaultSelect placeholder='Instagram, Facebook, Twitter'
                                                   label='Select Platform (Optional)'
                                                   hidePlaceOnHover={true}
                                                   onSelect={(selectedOption) => {
                                                       setSmePlatforms(selectedOption)
                                                   }}
                                                   isMulti={true}
                                                   options={SocialMediaArray}
                                    />
                                    <DefaultSelect placeholder='Static'
                                                   label='Content (Optional)'
                                                   hidePlaceOnHover={true}
                                                   onSelect={(selectedOption) => {
                                                       setContentType(selectedOption.value)
                                                   }}
                                                   selectedValue={{
                                                       value: contentType,
                                                       label: contentType
                                                   }}
                                                   options={postType}
                                    />


                                    {
                                        contentType === 'carousel' ? (
                                                <DefaultSelect placeHolder='Assign Creative to this post'
                                                               label='Assign Creative to this post'
                                                               hidePlaceOnHover={true}
                                                               onSelect={(selectedOption) => {
                                                                   setCreativeId(selectedOption)
                                                               }}
                                                               isMulti={true}
                                                               options={
                                                                   creatives.map((creative) => {
                                                                       return {
                                                                           value: creative._id,
                                                                           label: creative.title
                                                                       }
                                                                   })
                                                               }
                                                />
                                            )
                                            : (
                                                <DefaultSelect placeHolder='Assign Creative to this post'
                                                               label='Assign Creative to this post'
                                                               hidePlaceOnHover={true}
                                                               onSelect={(selectedOption) => {
                                                                   setCreativeId(selectedOption)
                                                               }}
                                                               isMulti={false}
                                                               options={
                                                                   creatives.map((creative) => {
                                                                       return {
                                                                           value: creative._id,
                                                                           label: creative.title
                                                                       }
                                                                   })
                                                               }
                                                />
                                            )
                                    }
                                </InputWrapper>
                            </ModalBody>
                            <ModalFooter>
                                {
                                    existingPost ? (
                                        <Buttoni onClick={editPost} disabled={buttonLoading}>
                                            {buttonLoading ? <>Updating...</> : <>Update Post</>}
                                        </Buttoni>
                                    ) : (
                                        <Buttoni onClick={cretePost} disabled={buttonLoading}>
                                            {buttonLoading ? <>Adding...</> : <>Add Post</>}
                                        </Buttoni>
                                    )
                                }
                            </ModalFooter>
                        </Modal>
                    </ModalContainer>
                ) : null}
            </>
        );
    }
;

export default PostForm;
