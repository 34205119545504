// Import Statements
import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Textarea,
    Tooltip,
    Typography,
} from '@mui/joy';
import {AiOutlineCamera, AiOutlineDelete, AiTwotoneEdit} from 'react-icons/ai';
import {toast, Toaster} from 'react-hot-toast';
import api from '../../../api';
import {extractInitialsFromEmail, hasOnlySpace} from '../../../Components/Helpers/helper';
import Input from "@mui/joy/Input";

const UserViewComponent = ({user: propsUser, allowEdit, close, changeUser}) => {
    const [editMode, setEditMode] = useState(false);
    const [user, setUser] = useState({
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        aboutProfile: '',
        displayPicture: null,
        c_displayPicture: null,
    });

    useEffect(() => {
        setUser({
            _id: propsUser._id,
            firstName: propsUser.firstName,
            lastName: propsUser.lastName,
            email: propsUser.email,
            aboutProfile: propsUser.aboutProfile,
            displayPicture: propsUser.displayPicture,
            c_displayPicture: propsUser.c_displayPicture,
        });
    }, [propsUser]);

    const checkInputValues = () => {
        if (!user.firstName || hasOnlySpace(user.firstName)) {
            toast.error('Please enter a valid first name');
            return false;
        }
        if (!user.lastName || hasOnlySpace(user.lastName)) {
            toast.error('Please enter a valid last name');
            return false;
        }
        if (!user.aboutProfile || hasOnlySpace(user.aboutProfile)) {
            toast.error('Please enter a valid about section');
            return false;
        }
        return true;
    };

    const handleProfileUpdate = async () => {
        if (!checkInputValues()) return;

        try {
            const formData = new FormData();
            formData.append('firstName', user.firstName);
            formData.append('lastName', user.lastName);
            formData.append('aboutProfile', user.aboutProfile);

            if (user.displayPicture instanceof File) {
                formData.append('displayPicture', user.displayPicture);
            }

            const res = await api.updateProfilePicture(formData, user._id);

            setUser((prev) => ({
                ...prev,
                displayPicture: res.data.displayPicture,
                c_displayPicture: res.data.c_displayPicture,
                aboutProfile: res.data.aboutProfile,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
            }));

            changeUser(res.data);
            setEditMode(false);
            close();
            toast.success('Profile updated successfully');
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };

    const handleImageRemove = async () => {
        try {
            await api.updateProfilePicture({
                removeImageTrue: true
            }, user?._id);
            setUser((prev) => ({...prev, displayPicture: null, c_displayPicture: null}));
            toast.success('Profile picture removed successfully');
        } catch (error) {
            toast.error(error.response?.data?.message || 'An error occurred');
        }
    };


    const handleProfilePictureUpdate = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.style.display = 'none';

        fileInput.onchange = async (e) => {
            const file = fileInput.files?.[0];

            const formData = new FormData();
            formData.append('video', file);


            try {
                const res = await api.updateProfilePicture(formData, user?._id);

                setUser((prev) => ({
                    ...prev,
                    displayPicture: res.data.displayPicture,
                    c_displayPicture: res.data.c_displayPicture
                }));

                toast.success('Profile picture updated successfully');
            } catch (error) {
                toast.error(error.response?.data?.message || 'An error occurred');
            }
        };

        fileInput.click();

    };


    return (
        <>
            {/* Toaster for notifications */}
            <Toaster
                position="bottom-center"
                toastOptions={{
                    duration: 4000,
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        fontSize: '14px',
                        textAlign: 'center',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    },
                }}
            />

            <Modal open onClose={close}>
                <ModalDialog
                    size="sm"
                    sx={{borderRadius: 'md', p: 3}}
                >
                    <ModalClose/>

                    {/* Header */}
                    <Typography level="title-lg" textAlign="center">
                        Your Profile
                    </Typography>

                    {/* Profile Picture */}
                    <Box sx={{textAlign: 'center', mb: 3}}>
                        <Avatar
                            src={user.c_displayPicture ? `${process.env.REACT_APP_IMAGE_URL}${user.c_displayPicture}` : null}
                            alt={user.firstName}
                            sx={{width: 100, height: 100, mx: 'auto', fontSize: '2rem'}}
                        >
                            {!user.c_displayPicture && extractInitialsFromEmail(user.email)}
                        </Avatar>
                        {allowEdit && editMode && (
                            <Stack direction="row" spacing={1} justifyContent="center" mt={1}>
                                <Tooltip title="Change Profile Picture">
                                    <IconButton component="label" variant="outlined" color="neutral" onClick={
                                        handleProfilePictureUpdate
                                    }>
                                        <AiOutlineCamera/>
                                    </IconButton>
                                </Tooltip>
                                {user.c_displayPicture && (
                                    <Tooltip title="Remove Profile Picture">
                                        <IconButton variant="outlined" color="danger" onClick={
                                            handleImageRemove
                                        }>
                                            <AiOutlineDelete/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Stack>
                        )}
                    </Box>

                    <Divider sx={{mb: 3}}/>

                    {/* Form Fields */}
                    <Stack spacing={2}>
                        <Input
                            label="First Name"
                            value={user.firstName}
                            onChange={(e) => setUser({...user, firstName: e.target.value})}
                            disabled={!editMode}
                            required
                            placeholder="Your first name"
                        />
                        <Input
                            label="Last Name"
                            value={user.lastName}
                            onChange={(e) => setUser({...user, lastName: e.target.value})}
                            disabled={!editMode}
                            required
                            placeholder="Your last name"
                        />
                        <Input
                            label="Email"
                            value={user.email}
                            disabled
                            variant="soft"
                            placeholder="Your email address"
                        />
                        <Textarea
                            label="About"
                            minRows={3}
                            value={user.aboutProfile}
                            onChange={(e) => setUser({...user, aboutProfile: e.target.value})}
                            disabled={!editMode}
                            required
                            placeholder="Add a short description about yourself"
                        />
                    </Stack>

                    {/* Actions */}
                    {allowEdit && (
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 4}}>
                            {editMode ? (
                                <>
                                    <Button variant="plain" color="neutral" onClick={() => setEditMode(false)}
                                            sx={{mr: 2}}>
                                        Cancel
                                    </Button>
                                    <Button variant="solid" color="primary" onClick={handleProfileUpdate}>
                                        Save Changes
                                    </Button>
                                </>
                            ) : (
                                <Button
                                    variant="outlined"
                                    startDecorator={<AiTwotoneEdit/>}
                                    onClick={() => setEditMode(true)}
                                >
                                    Edit Profile
                                </Button>
                            )}
                        </Box>
                    )}
                </ModalDialog>
            </Modal>
        </>
    );
};

export default UserViewComponent;
