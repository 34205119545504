import React from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './pages/user/login';
import Board from './pages/Boards/board';
import Organisation from './pages/Organisation/organisation';
import ViewProjects from './pages/Projects/view-projects';
import NotFound from './pages/NotFound/notFound';
// import SideMenu from "./Components/Side-menu/side-menu";
import Notifications from "./pages/Notification/notification";
import Dashboard from "./pages/Dashboard/dashboard";
import SocialCalendarView from "./pages/social_calander/social-calander";
import PasswordManagerView from "./pages/password-manger/password-manager";
import ViewSettings from "./pages/Settings/view-settings";
import {CssVarsProvider} from '@mui/joy/styles';
import Sidebar from "./Components/Side-menu/side-menu2";


function App() {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (


        <CssVarsProvider>
            <BrowserRouter>
                <Routes>
                    {isAuthenticated ? (
                        <Route path="/" element={<Sidebar/>}>
                            <Route path="menu">
                                <Route path="dashboard" element={<Dashboard/>}/>
                                <Route path="organisation" element={<Organisation/>}/>
                                <Route path="project/:id" element={<ViewProjects/>}/>
                                <Route path=":id/:org/list" element={<Board/>}/>
                                <Route path="notifications" element={<Notifications/>}/>
                                <Route path="calendar/:id" element={<SocialCalendarView/>}/>
                                {/*/organisation/${params.id}/secrets*/}
                                <Route path="organisation/:id/secrets" element={<PasswordManagerView/>}/>
                                <Route path="settings" element={<ViewSettings/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                            <Route path='login' element={<Dashboard/>}/>
                        </Route>
                    ) : (
                        <>
                            <Route path="/">
                                <Route path="/" element={<Login/>}/>
                                <Route path="login" element={<Login/>}/>
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                        </>
                    )}
                </Routes>
            </BrowserRouter>
        </CssVarsProvider>
    );
}

export default App;
