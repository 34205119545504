const SocialMediaArray = [
    {
        value: "instagram",
        label: "Instagram",
    },
    {
        value: 'facebook',
        label: 'Facebook',
    },
    {
        value: 'x',
        label: 'x',
    },
    {
        value: 'linkedin',
        label: 'Linkedin',
    },
]


export default SocialMediaArray;
