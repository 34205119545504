import { LabelTag } from "./textInputStyle";

export default function TextInput({
                                      placeholder,
                                      value,
                                      onChange,
                                      type,
                                      label,
                                      hidePlaceOnHover,
                                      disabled,
                                      style
                                  }) {
    return (
        <>
            {type ? (
                <>
                    {type === 'textarea' ? (
                        <>
                            <label style={{ marginBottom: '10px' }}>{label}</label>
                            <LabelTag hidePlaceOnHover={hidePlaceOnHover}>
                <textarea
                    style={style}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder} // Pass the placeholder prop here
                    disabled={disabled}
                />
                            </LabelTag>
                        </>
                    ) : type === 'calendar' ? (
                        <>
                            <label>{label}</label>
                            <LabelTag hidePlaceOnHover={hidePlaceOnHover}>
                                <input
                                    style={style}
                                    type="date"
                                    onChange={onChange}
                                    value={value}
                                    placeholder={placeholder} // Pass the placeholder prop here
                                    disabled={disabled}
                                />
                            </LabelTag>
                        </>
                    ) : type === 'checkbox' ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '220px'
                                    }}
                                >
                                    <label>{label}</label>
                                    <LabelTag hidePlaceOnHover={hidePlaceOnHover}>
                                        <input
                                            style={style}
                                            type="checkbox"
                                            onChange={onChange}
                                            value={value}
                                            placeholder={placeholder} // Pass the placeholder prop here
                                            disabled={disabled}
                                        />
                                    </LabelTag>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <label>{label}</label>
                            <LabelTag hidePlaceOnHover={hidePlaceOnHover}>
                                <input
                                    style={style}
                                    type={type}
                                    onChange={onChange}
                                    value={value}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                />
                            </LabelTag>
                        </>
                    )}
                </>
            ) : (
                <>
                    {label && <label>{label}</label>}
                    <LabelTag hidePlaceOnHover={hidePlaceOnHover}>
                        <input
                            style={style}
                            type="text"
                            onChange={onChange}
                            value={value}
                            placeholder={placeholder} // Pass the placeholder prop here
                            disabled={disabled}
                        />
                    </LabelTag>
                </>
            )}
        </>
    );
}
