import axios from "axios";
import { toast } from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MemberCapsule } from "./addMembserStyle";
import DefaultSelect from "../../../Components/default-select/default-select";
import TextInput from "../../../Components/custome-tags/TextInput/textInput";
import { ButtonGroup, DialogTitle, Divider, IconButton, Modal, ModalDialog } from "@mui/joy";
import { ModalFooter, ModalHeader } from "../../../Components/UI/modal.style";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Stack from "@mui/joy/Stack";
import Ellipsis from "../../../Components/Ellipsis/Ellipsis";
import { AiOutlinePlus } from "react-icons/ai";

// Roles Definition
export const roles = [
    {
        value: 'admin',
        label: 'Product Owner',
        explanation: 'Product Owner can add and remove members, create, delete, publish, and review content'
    },
    {
        value: 'creator',
        label: 'Creator',
        explanation: 'Creator can create content'
    },
    {
        value: 'reviewer',
        label: 'Reviewer',
        explanation: 'Reviewer can review content'
    },
    {
        value: 'publisher',
        label: 'Publisher',
        explanation: 'Publisher can publish content'
    },
    {
        value: 'user',
        label: 'Viewer',
        explanation: 'Viewer can only view content'
    }
]

const AddMember = ({ show, close, project }) => {
    const params = useParams();
    const navigate = useNavigate();

    // State management
    const [role, setRole] = useState("");
    const [userId, setUserId] = useState('');
    const [user, setUser] = useState('');
    const [organisationMember, setOrganisationMember] = useState([]);
    const [projectMembers, setProjectMembers] = useState([]);
    const [showAddRole, setShowAddRole] = useState(false);
    const [filteredOrgMembers, setFilteredOrgMembers] = useState([]);
    const [externalUser, setExternalUser] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // Fetch project and organization members on modal open
    useEffect(() => {
        resetStates();
        fetchProjectMembers();
        fetchOrganisationMembers();
    }, [show]);

    // Reset state when the modal is opened
    const resetStates = () => {
        setRole('');
        setUserId('');
        setUser('');
        setEmail('');
        setShowAddRole(false);
        setExternalUser(false);
    }

    // Fetch project members
    const fetchProjectMembers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}project/users/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            setProjectMembers(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    // Fetch organisation members
    const fetchOrganisationMembers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}organisation/users/${params.org}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            const orgMembers = response.data;
            const filteredMembers = orgMembers.filter(member => !projectMembers.some(pMember => pMember._id === member._id));
            setOrganisationMember(orgMembers);
            setFilteredOrgMembers(filteredMembers);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    // Add an external user
    const addExternalUser = async () => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}project/AddNonOrganisationUser/${params.id}`,
                {
                    email: email,
                    role: role,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            toast.success("External Member Added Successfully");
            closeModal();
        } catch (error) {
            toast.error("Failed to add external user");
            setLoading(false);
        }
    }

    // Add a member from the organization
    const addMember = async () => {
        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}project/addUser/${params.id}`,
                {
                    userId: userId,
                    role: role,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            toast.success("Member Added Successfully");
            closeModal();
        } catch (error) {
            toast.error("Failed to add member");
            setLoading(false);
        }
    };

    // Close modal and reset state
    const closeModal = () => {
        setLoading(false);
        close();
        resetStates();
    }

    return (
        <>
            {/* Modal for selecting a role for a user */}
            <Modal open={showAddRole} onClose={() => setShowAddRole(false)}>
                <ModalDialog>
                    <ModalHeader>Select Role For {user}</ModalHeader>
                    <Divider />
                    <form onSubmit={event => {
                        event.preventDefault();
                        addMember();
                    }}>
                        <Stack spacing={2}>
                            <FormControl>
                                <DefaultSelect onSelect={(selectedOption) => {
                                    setRole(selectedOption.value);
                                }}
                                               placeholder={'Select Member Role'}
                                               options={roles}
                                />
                            </FormControl>
                            <Button size={'md'} loading={loading} type="submit">Add</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>

            {/* Modal for adding members */}
            <Modal open={show} onClose={close}>
                <ModalDialog>
                    <DialogTitle>Add Project Members</DialogTitle>
                    <Divider />
                    {filteredOrgMembers.length > 0 ? (
                        <>
                            {filteredOrgMembers.map((member) => (
                                <MemberCapsule key={member._id}>
                                    <div onClick={() => {
                                        setUserId(member._id);
                                        setUser(member.email);
                                        setShowAddRole(true);
                                    }}>
                                        <p>
                                            <Ellipsis>{member.email}</Ellipsis>
                                        </p>
                                        <IconButton
                                            variant='solid' size='sm' color='primary' sx={{
                                            borderRadius: '50%',
                                        }}>
                                            <AiOutlinePlus />
                                        </IconButton>
                                    </div>
                                </MemberCapsule>
                            ))}
                            <Button size='sm' variant='soft' onClick={() => setExternalUser(true)}>Add External Member</Button>
                        </>
                    ) : (
                        <>
                            <p>All Members of <b>{project?.organisation?.name}</b> organization have been added to <b>{project?.name}</b> project
                                <br /><br />
                                <span style={{ fontSize: '14px' }}>You need to add new members to the <b>{project?.organisation?.name}</b> organisation in order to add them to this project</span>
                            </p>
                            <Divider />
                            <ModalFooter>
                                <ButtonGroup variant='outlined' color='primary'>
                                    <Button variant='soft' onClick={() => setExternalUser(true)}>Add External User</Button>
                                    <Button onClick={() => {
                                        close();
                                        navigate(`/menu/project/${params.org}`);
                                    }}>
                                        Add New Member to <Ellipsis>{project?.organisation?.name}</Ellipsis>
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </>
                    )}
                </ModalDialog>
            </Modal>

            {/* Modal for adding external user */}
            <Modal open={externalUser} onClose={() => setExternalUser(false)}>
                <ModalDialog>
                    <DialogTitle>Add External Project Members</DialogTitle>
                    <Divider />
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                            addExternalUser();
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <TextInput label={'Email'} onChange={(e) => setEmail(e.currentTarget.value)} />
                            </FormControl>
                            <FormControl>
                                <DefaultSelect onSelect={(selectedOption) => {
                                    setRole(selectedOption.value)
                                }}
                                               placeholder={'Select Member Role'}
                                               options={roles}
                                />
                            </FormControl>
                            <Divider />
                            <Button type="submit" loading={loading}>Add</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </>
    )
}

export default AddMember;
