import {createSlice} from "@reduxjs/toolkit";
import {fetchOrganisations} from "../../api.js";


const initialState = {
    data: [],
}

const organisationSlice = createSlice({
    name: "organisation",
    initialState,
    reducers: {
        setOrganisationData: (state, action) => {
            state.data = action.payload;
        },
    },
});


export default organisationSlice.reducer;

export const {setOrganisationData} = organisationSlice.actions;


export const fetchOrganisationData = () => async (dispatch) => {
    try {
        const organisationData = await fetchOrganisations();
        dispatch(setOrganisationData(organisationData));
    } catch (error) {
        dispatch(setOrganisationData([]));
    }
};




