import React from 'react';
import styled from 'styled-components';
import {Tooltip} from "@mui/joy";

const EllipsisContainer = styled.span`
  width: 100px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Ellipsis = ({children, character }) => {
    return (
        <Tooltip title={children}>
            <EllipsisContainer
            >
                {
                    character ? children.length > character ? children.substring(0, character) + "..." : children : children
                }
            </EllipsisContainer>
        </Tooltip>
    );
};

export default Ellipsis;
