import React, {useEffect, useRef, useState} from 'react';
import {ChatArea, ChatContainer, ChatInput, ChatInputArea, ChatMessage} from './chat.style';
import {Buttoni} from "../../pages/Boards/board.style";
import {breakLineWords, formatDate, removeDomainNameFromEmail} from "../Helpers/helper";
import {AiOutlineSend} from "react-icons/ai";
import axios from "axios";

const Chat = ({videoId, projectId, socket}) => {
    // const [socket, setSocket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const chatAreaRef = useRef(null);


    // Initialize Socket.io connection on component mount
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}creative/get-chat-messages/${videoId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            setMessages(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, [videoId]);

    // Listen for new chat messages from the server
    useEffect(() => {
        if (!socket) {
            return;
        }

        const newMessageHandler = ({videoId: messageVideoId, sender, message}) => {
            if (messageVideoId !== videoId) {
                return;
            }
            setMessages((prevMessages) => [...prevMessages, {sender, message, timestamp: new Date()}]);
        };

        socket.on('newChatMessage', newMessageHandler);

        return () => {
            socket.off('newChatMessage', newMessageHandler);
        };

    }, [projectId, socket, videoId]);

    useEffect(() => {
        if (chatAreaRef.current) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [messages]);


    const handleKeyDown = (event) => {
        if (
            event.keyCode === 13 && event.shiftKey === false
        )
            if (event.keyCode === 13) {
                event.preventDefault();
                handleSendMessage();
            }
    }

    const handleNewMessageChange = (event) => {
        setNewMessage(event.target.value);
    };

    const handleSendMessage = () => {
        if (!socket) {
            return;
        }
        socket.emit('newChatMessage', {
            videoId,
            sender: 'User',
            projectId: projectId,
            message: newMessage,
            timestamp: new Date()
        });
        setNewMessage('');
    };


    return (
        <ChatContainer>
            <ChatArea ref={chatAreaRef}>
                {messages.map((message, index) => (
                    <ChatMessage isSender={
                        message.sender === localStorage.getItem('userName')
                    }>
                        <span style={
                            {
                                color: 'grey',
                                fontSize: '10px',
                            }
                        }>{formatDate(message.timestamp)} <span
                            style={
                                {
                                    color: 'deeppink',
                                    fontSize: '13px',
                                }
                            }

                        >{removeDomainNameFromEmail(message.sender)}: </span></span>


                        <span><pre
                            style={{
                                fontSize: "16px",
                                margin: "0",
                                padding: "0",
                                textAlign: "left",
                                whiteSpace: 'pre-wrap',
                            }}

                        >{message.message}</pre></span>
                    </ChatMessage>
                ))}
            </ChatArea>
            <ChatInputArea>
                <ChatInput type="text" value={newMessage} onChange={handleNewMessageChange} onKeyDown={handleKeyDown}/>
                <Buttoni onClick={handleSendMessage}>
                    <AiOutlineSend/>
                </Buttoni>
            </ChatInputArea>
        </ChatContainer>
    );
};

export default Chat;
