import styled from "styled-components";
import {Buttoni} from "../Boards/board.style";

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  width: 100%;
  height: 100%;
`;


export const CalendarHeaderMenu = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 95%;
  height: 10%;
`;

export const MonthDirectionHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  // Month
  h2 {
    margin: 0 10px;
    font-weight: 400;
  }
`;


export const DirectionButton = styled(Buttoni)`
  border-radius: 0;
  border: 1px solid #ccc;
  background: #f5f5f5;
  color: black;
  font-weight: bold;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;
  font-size: 10px;

  &:hover {
    background: #ccc;
  }

`;


export const Calendar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 90%;
  border-radius: 5px;
  margin: 10px 30px 0 0;
`;


export const DayColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 14.28%;
  height: 100%;
`;


export const WeekDay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5%;
  padding: 5px;
  color: ${props => props.isWeekend ? '#fd4a4f' : '#606060'};
  background: #f2f2f2;
  border: 1px solid #ccc;
  border-right: none;

`;


export const PlusIcon = styled.div`
  position: absolute;
  top: 17px;
  right: 0;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  background: #ababab;
  color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


export const Day = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 18%;
  background: #f8f8f8;

  border: .5px solid #ccc;
  border-top: none;
  border-right: none;
  cursor: pointer;


  &:hover {
    background: #eeeeee;
  }

  &:hover ${PlusIcon} {
    opacity: 1;
  }
`;


export const DayDateHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 95%;
  flex: 1;

  p {
    margin: 4px;
    font-size: 15px;
    font-weight: 500;
    width: 15px;
    height: 15px;
    padding: 5px;
    background: ${props => props.isHoliday ? '#fd4a4f' : 'transparent'};
    border-radius: 50%;
    color: ${props => props.isHoliday ? 'white' : '#606060'};
    display: flex;
    align-items: center;
    justify-content: center;
  }


`;

export const ShowDateHolidays = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  flex-direction: column;
  background-color: yellow;
  max-height: 80px;

`;

export const DayPostHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 95%;
  flex: 4;
  overflow-y: scroll;
`;


export const PreviewPostOptionHolder = styled.div`
  display: flex;
  align-items: start;
  position: absolute;
  right: 10px;
  width: 70px;
  justify-content: space-evenly;
`;

export const PreviewPostCard = styled.div`
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 50rem;
  height: 24rem;
  position: absolute;
  top: 10rem;
  right: 15rem;
  z-index: 100;
  display: flex;
  background: white;
  padding: 15px;
  border-radius: 15px;

  transition: all 0.3s ease;
  animation: fadeIn 0.3s ease;

  //also in closing
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateX(100px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  // add shadow
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const PreviewPostCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
  width: 100%;
  flex: 1;


  h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #131314;
  }

  p {
    margin: 5px 0 0 0;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const PreviewPostCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  text-align: left;
  width: 100%;
  overflow-y: scroll;
  flex: 5;


  h2 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #131314;


  }

  p {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #131314;

  }
`;

export const PreviewPostCardBodySection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin: 5px 0;
`;


export const DayPost = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${props => props.color};
  color: #000000;


  background: lavender;

  background: ${props => props.color ? props.color : `hsl(${Math.floor(Math.random() * 360)}, 100%, 90%)`};
  margin: 3px 0;
  height: auto;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  //&:hover {
  //  transform: scale(1.02);
  //}

    // &:hover ${PreviewPostCard} {
  //   display: flex;
  // }


  // Title
  h3 {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    text-align: start;
  }

  // Time
  h4 {
    margin: 0;
    font-size: 10px;
    font-weight: 400;
  }
`;



