import React from 'react';
import {CloseButton, InputWrapper, Modal, ModalBody, ModalContainer, ModalFooter, ModalHeader} from "../UI/modal.style";
import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../custome-tags/TextInput/textInput";
import DefaultSelect from "../default-select/default-select";
import {Buttoni} from "../../pages/Boards/board.style";
import api from "../../api";
import {toast} from "react-hot-toast";
import languageArray from "../../constants/languages";


const AiContentForm = ({show, close, creativeId, onClose}) => {
    const [contentLanguage, setContentLanguage] = React.useState("");
    const [targetGroup, setTargetGroup] = React.useState("");
    const [userInstructions, setUserInstructions] = React.useState("");
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const createProject = async () => {
        setButtonLoading(true);
        const data = {
            language: contentLanguage,
            targetGroup: targetGroup,
            userInput: userInstructions,
        };
        const result = await api.generateCaption(creativeId, data);
        if (result?.result) {
            setButtonLoading(false);
            onClose(result);
            toast.success("Caption Generated Successfully");
            close();
        } else {
            setButtonLoading(false);
            close();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default behavior of the Enter key (form submission)
            createProject();
        }
    };


    return (
        <>
            {show ? (
                <ModalContainer onKeyDown={handleKeyDown} tabIndex="0">
                    <Modal>
                        <ModalHeader>
                            <h2 className="modal_header-title">
                                Magic AI Caption
                            </h2>
                            <CloseButton onClick={() => {
                                close()
                            }}>
                                <IoIosCloseCircleOutline/>
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <InputWrapper>
                                <TextInput type='text' placeholder='Target Group'
                                           label="Target Group (Optional)"
                                           hidePlaceOnHover={true}
                                           onChange={(e) => {
                                               setTargetGroup(e.target.value)
                                           }}/>
                                <TextInput type='textarea'
                                           placeholder='Remove all Emojis and caption should be only 4 lines'
                                           hidePlaceOnHover={true}
                                           label="Enter Your Instructions (Optional)"
                                           onChange={(e) => {
                                               setUserInstructions(e.target.value)
                                           }}/>

                                <DefaultSelect placeholder='Choose the language'
                                               label='Content Language'
                                               hidePlaceOnHover={true}
                                               onSelect={(selectedOption) => {
                                                   setContentLanguage(selectedOption.value)
                                               }}
                                               options={languageArray}/>
                            </InputWrapper>
                        </ModalBody>
                        <ModalFooter>
                            <Buttoni onClick={createProject} disabled={buttonLoading}>
                                {buttonLoading ? <>Generating...</> : <>Generate</>}
                            </Buttoni>
                        </ModalFooter>
                    </Modal>
                </ModalContainer>
            ) : null}
        </>
    );
};

export default AiContentForm;
