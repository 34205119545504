import {createSlice} from "@reduxjs/toolkit";
import {fetchProjects} from "../../api.js";

const initialState = {
    data: [],
}

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setProjectsData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export default projectSlice.reducer;


export const {setProjectsData} = projectSlice.actions;

export const fetchProjectsData = () => async (dispatch) => {
    try {
        const projectsData = await fetchProjects();
        dispatch(setProjectsData(projectsData));
    } catch (error) {
        dispatch(setProjectsData([]));
    }
};


