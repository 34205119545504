import styled, {keyframes} from "styled-components";

export const ScreenWrapper = styled.div`
    width: 85%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
`;


const marqueeAnimation = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
`;

export const Header = styled.div`
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    background-color: white;
    border-bottom: 1px solid #e0e0e0;

    h1 {
        font-size: 1.5rem;
        margin-left: 40px;
        display: flex;
        align-items: center;
    }
`;

export const MainBody = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BackButton = styled.button`
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
`



export const BreadCrumbsHolder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    width: 100%;
    margin-left: 3%;
`;


export const SelectFile = styled.input`
    ::file-selector-button {
        margin-right: 20px;
        border: none;
        background: linear-gradient(104deg, rgba(84, 40, 154, 1) 18%, rgba(76, 37, 142, 1) 25%, rgba(15, 13, 49, 1) 92%);
        padding: 10px 20px;
        border-radius: 10px;
        color: #fff;
        cursor: pointer;
        transition: background .2s ease-in-out;
    }

    ::file-selector-button:hover {
        background: linear-gradient(104deg, rgba(84, 40, 154, .9) 18%, rgba(76, 37, 142, .9) 25%, rgba(15, 13, 49, .9) 92%);
    }

`;

export const ToolTip = styled.div`
    position: relative;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 80%;
    margin: 5px 0;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    img {
        position: absolute;
        right: -10px
    }

    sup {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        color: #000084;
        text-transform: uppercase;
    }

    span {
        min-width: 200px;
        max-width: 400px;
        top: -10px;
        left: 0;
        transform: translate(-30%, -100%);
        padding: 10px 20px;
        color: #ffffff;
        background-color: #009cdc;
        font-weight: normal;
        font-size: 14px;
        border-radius: 8px;
        position: absolute;
        z-index: 999;
        box-sizing: border-box;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        visibility: hidden;
    }

    :hover span {
        visibility: visible;
    }

    i {
        position: absolute;
        top: 100%;
        left: 60%;
        margin-left: -15px;
        width: 30px;
        height: 15px;
        overflow: hidden;

        ::after {
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: #009cdc;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        }
    }
}`;
