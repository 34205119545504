import React, {useState} from 'react';
import styled from 'styled-components';
import {IoIosOptions} from "react-icons/io";

const MenuContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '0px'};
  width: ${(props) => props.width || '100px'};
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 3000;
  font-size: 10px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background: ${(props) => (props.background && props.background)};

  &:hover {
    background: ${(props) => (!props.background && '#f0f0f0')};
  }
`;

const HoverArea = styled.div`
  position: relative;
  display: inline-block;
`;

const HoverMenu = ({options, top, left, width, iconSize, icon}) => {
    const [showMenu, setShowMenu] = useState(false);

    const handleClick = (event, onClick) => {
        event.stopPropagation(); // Prevent event propagation to the parent component
        onClick();
    };

    return (
        <HoverArea
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
        >
            <div style={{padding:'5px',background:'white',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={(e) => e.stopPropagation()}>{
                icon ? icon :
                    <IoIosOptions size={iconSize}/>}</div>
            <MenuContainer show={showMenu} top={top} left={left} width={width}>
                {options.map((option, index) => (
                    <MenuItem key={index} background={option?.color}
                              onClick={(event) => handleClick(event, option.onClick)}>
                        {option.label}
                    </MenuItem>
                ))}
            </MenuContainer>
        </HoverArea>
    );
};

export default HoverMenu;
