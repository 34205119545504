import styled from "styled-components";

export const NavBarWrapper = styled.div`
    width: 100%;
    height: 12%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
`;

export const NavBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
`;


export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f7;
`;

export const SideBar = styled.div`
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-right: 1px solid #e0e0e0;
    background-color: peachpuff;
`;

export const MainBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    //background-image: url('https://images.unsplash.com/photo-1519681393784-d120267933ba?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1124&q=100');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const Partition = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 10px;
`;

// export const PartitionTitle = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px;
//
//
//     p {
//         font-size: 30px;
//         font-weight: 600;
//         color: black;
//     }
// `;


export const CreateButton = styled.div`
    width: 200px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 0 10px;
    margin: 10px 0;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0.09)),
    radial-gradient(50% 50% at 50% 50%, #1B0E5A 0%, #21013C 100%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;


export const PartitionBody = styled.div`
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
    font-size: 20px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    min-height: 8rem;
    max-height: 600px;

    scroll-behavior: smooth; /* Add smooth scrolling to the partition body */
    overflow-y: scroll; /* Add scrolling to the partition body */

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bab8b8;
    }
`;

export const Card = styled.div`
    width: 250px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    background-color: white;
    border-radius: 18px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    margin: 10px;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.08);
        //background-image: url('https://source.unsplash.com/random/200×200?${props => props.backgroundImage + ',' + props.backgroundLogo || 'digital marketing, light colors'}');
    background-position: center;
    background-size: cover;
    position: relative;


    &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        background-color: rgb(241, 241, 243);
    }
`;

export const CardNameHolder = styled.div`
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    color: white;
    height: 100%;
    justify-content: center;
    width: 100%;

    //background: rgb(0, 0, 0);
    //background: linear-gradient(74deg, rgba(0, 0, 0, 0.3688068977591037) 0%, rgba(0, 0, 0, 0.6349133403361344) 70%, rgba(0, 0, 0, 0.8590029761904762) 100%);

    p {
        width: 120px;
        color: #000;
        display: flex;
        flex-direction: column;
        direction: ltr;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        align-items: flex-start;
        justify-content: center;
    }

    img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 15px;
    }
`;
