import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {toast} from "react-hot-toast";

const DropZone = styled.div`
  width: ${(props) => props.width || '300px'};
  height: ${(props) => props.height || '200px'};
  border: 2px dashed ${(props) => props.borderColor || '#ccc'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const FileInput = styled.input`
  display: none;
`;

const FileNames = styled.div`
  margin: 10px;
  width: 60%;
    .innerDiv{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;
const ImagePreview = styled.img`
  width: 100px !important;
  height: 50px !important;
  object-fit: cover;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const FileUpload = ({onFilesSelected, width, height, borderColor, allowedTypes}) => {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files).filter(file =>
            allowedTypes.includes(file.type)
        );
        handleFiles(files);
    };

    const handleFiles = (files) => {
        setSelectedFiles(files);
        if (onFilesSelected) {
            onFilesSelected(files);
        }
    };

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (e) => {
        const files = e.target.files;
        const filteredFiles = Array.from(files).filter(file => allowedTypes.includes(file.type));
        if (filteredFiles.length !== files.length) {
            return  toast.error('Please select ' + allowedTypes.join(', ') + ' files only ');
        }
        const selectedFiles = Array.from(e.target.files).filter(file =>
            allowedTypes.includes(file.type)
        );
        handleFiles(selectedFiles);
    };

    return (
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <DropZone
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={handleFileInputClick}
                width={width}
                height={height}
                borderColor={borderColor}
            >
                Drop files here or click to select
            </DropZone>
            <FileInput
                ref={fileInputRef}
                type="file"
                onChange={handleFileInputChange}
                multiple
                accept={allowedTypes.join(",")}
            />
            <FileNames>
                {selectedFiles.length > 0 && selectedFiles.map((file, index) => (
                    <div key={index} className={'innerDiv'}>
                        {file.type.startsWith('image/') && (
                            <ImagePreview src={URL.createObjectURL(file)} alt={file.name} />
                        )}<div style={{textAlign:"center",width:'100%'}}>{file.name}</div>
                    </div>
                ))}
            </FileNames>
        </div>
    );
};

FileUpload.propTypes = {
    onFilesSelected: PropTypes.func,
    width: PropTypes.string,
    height: PropTypes.string,
    borderColor: PropTypes.string,
    allowedTypes: PropTypes.arrayOf(PropTypes.string),
};

export default FileUpload;
