import styled from "styled-components";


export const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f7;
`;

export const SideBar = styled.div`
  height: 100%;
  width: 250px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f3;
  position: relative;
  border-left: 1px solid #e0e0e0;
  display: ${props => (props?.display ? 'flex' : 'none')};
  transition: all 0.5s ease-in-out;
  transform: ${props => (props.display ? 'translateX(0)' : 'translateX(130%)')};
  padding-top: 20px;

  .members-wrapper {
    height: 90%;
    width: calc(100% - 20px);
    overflow-Y: auto;
    overflow-X: hidden;
    margin-Top: 15px;
    border-Radius: 5px;
    padding: 0 10px;
    text-align: center;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }
`;

export const MainBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 12%;
    width: calc(100% - 30px);
    padding: 0 15px;
    

    .org-name {
        display: flex;
        align-items: center;
    }

    img {
        padding: 5px;
        width: 40px;
        margin-left: 20px;
    }

    h1 {
        font-size: 30px;
        margin-left: 10px;
        color: #1f0545;
    }
`;

export const Partition = styled.div`
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`;

export const PartitionTitle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

  
`;


export const PartitionBody = styled.div`
    width: 98%;
    min-height: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0 62px;
    justify-items: center;
    flex-wrap: wrap;
    padding: 10px;
    font-size: 20px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    max-height: 600px;

    scroll-behavior: smooth; /* Add smooth scrolling to the partition body */
    overflow-y: scroll; /* Add scrolling to the partition body */

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #bab8b8;
    }
`;


export const Card = styled.div`
    width: 260px;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    border-radius: 25px;
    border: 1px solid #ebedef;
    margin-bottom: 15px;
    background: #f9faff;
    box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.08);
    position: relative;
    z-index: 0;

    > img {
        width: 50%;
        height: 100%;
        border-radius: 25px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    > video {
        width: 50%;
        height: 100%;
        border-radius: 25px;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    &:hover {
        transform: scale(1.05);
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        z-index: 1;
    }`;

export const CardNameHolder = styled.div`
    background: #f9faff;
    max-width: 40%;
    width: 40%;
    padding-right: 10px;
`;

export const CardOrganisationHolder = styled.div`
    font-size: 13px;
    background: #f9faff;
    border-radius: 12px;
    padding: 0 10px 5px 0;
    max-width: 40%;
    width: 40%;
    margin: 0;
    color: #c2c2c2;
    font-weight: 400;

    img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }
`;




