import React from 'react';
import styled, {keyframes} from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
`;

const SkeletonTr = styled.tr`
  background: linear-gradient(90deg, #f1f1f1 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
  margin: 10px;
  border-bottom: 1px solid #ddd;
    &:last-child {
        border-bottom: none;
    }
`;

const SkeletonTd = styled.td`
  margin: 10px;
  padding: 10px;
  text-align: center;
  height: 50px;
`;

const TableSkeletonLoader = ({rowStyles, cellStyles, numRows, numCells}) => {

    return (
        <>
            {Array(numRows).fill().map((_, index) => (
                <SkeletonTr key={index} style={rowStyles}>
                    {Array(numCells).fill().map((_, index) => (
                        <SkeletonTd key={index} style={cellStyles}/>
                    ))}
                </SkeletonTr>
            ))}
        </>
    );

};

export default TableSkeletonLoader;
