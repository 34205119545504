import React, { useState } from 'react';
import {SegmentButton, SegmentWrapper} from "./segmentStyle.jsx";


const Segment = ({ values, selectedValue, onSegmentChange , style,fontSize }) => {
    const [currentValue, setCurrentValue] = useState(selectedValue);

    const handleSegmentClick = (value: number) => {
        setCurrentValue(value);
        onSegmentChange(value);
    };

    return (
        <SegmentWrapper style={style}>
            {values.map((value, index) => (
                <SegmentButton
                    fontSize={fontSize}
                    key={value}
                    isActive={value === values[currentValue]}
                    onClick={() => handleSegmentClick(index)}>{value}</SegmentButton>
            ))}
        </SegmentWrapper>
    );
};

export default Segment;
