import React, {useEffect, useState} from "react";
import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../../../Components/custome-tags/TextInput/textInput";
import {Buttoni} from "../../Boards/board.style";
import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader,
} from "../../../Components/UI/modal.style";
import {toast} from "react-hot-toast";
import axios from "axios";
import DefaultSelect from "../../../Components/default-select/default-select";
import postActivities from "../../../constants/postActivities";
import api from "../../../api";

const AiGenerateCalendar = ({show, close, projectId, onClose}) => {
    const [currentStep, setCurrentStep] = useState(1);

    // const [formData, setFormData] = useState({
    //   brandName: "",
    //   day: "",
    //   brandWebsite: "",
    //   missionStatement: "",
    //   targetAudience: "",
    //   slugLine: "",
    //   activities: "",
    //   usp: "",
    //   staticPost: "",
    //   shortVideo: "",
    //   carouselPost: "",
    //   gif: "",
    //   customerTestimonial: "",
    //   startDate: "",
    //   endDate: "",
    // });

    const [brandName, setBrandName] = React.useState("");
    const [day, setDay] = React.useState(1);
    const [brandWebsite, setBrandWebsite] = React.useState("");
    const [missionStatement, setMissionStatement] = React.useState("Marketing");
    const [targetAudience, setTargetAudience] = React.useState("");
    const [slugLine, setSlugLine] = React.useState("");
    const [usp, setUsp] = React.useState("");
    const [staticPost, setStaticPost] = React.useState("");
    const [shortVideo, setShortVideo] = React.useState("");
    const [carouselPost, setCarouselPost] = React.useState("");
    const [gif, setGif] = React.useState("");
    const [customerTestimonial, setCustomerTestimonial] = React.useState("");
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [additionalActivities, setAdditionalActivities] = React.useState("")
    const [alreadyFilled, setAlreadyFilled] = React.useState(false)


    // create a function that will clear all the states
    function clearAllStates() {
        setBrandName("");
        setDay("");
        setBrandWebsite("");
        setMissionStatement("");
        setTargetAudience("");
        setSlugLine("");
        setUsp("");
        setStaticPost("");
        setShortVideo("");
        setCarouselPost("");
        setGif("");
        setCustomerTestimonial("");
        setStartDate("");
        setEndDate("");
        setCurrentStep(1);
    }

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const [buttonLoading, setButtonLoading] = useState(false);

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        setButtonLoading(true);
        close();

        const data = {
            brandName: brandName,
            day: day,
            brandWebsite: brandWebsite,
            missionStatement: missionStatement,
            targetAudience: targetAudience,
            slugLine: slugLine,
            usp: usp,
            projectId: projectId,
            staticPost: staticPost,
            shortVideo: shortVideo,
            carouselPost: carouselPost,
            gif: gif,
            customerTestimonial: customerTestimonial,
            startDate: startDate,
            endDate: endDate,
            additionalActivities: additionalActivities
        };


        // add days to start date to get end date from day

        const enddate = new Date(startDate);
        enddate.setDate(enddate.getDate() + parseInt(day));
        data.endDate = enddate.toISOString().split("T")[0];


        await toast.promise(
            axios.post(process.env.REACT_APP_API_URL + "posts/FillWithAI", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }),
            {
                loading:
                    "Filling Calendar... 👀 \n (We are Taking Care of your Social Media Calendar, Please wait)",
                success: (res) => {
                    toast.success("Calendar Generated ", {duration: 2000});
                    setButtonLoading(false);
                    onClose(res);
                    clearAllStates();
                    close();
                },
                error: (err) => {
                    toast.error("Whoops, Something went wrong", {duration: 2000});
                    setButtonLoading(false);
                    clearAllStates();
                    close();
                },
            }
        );
        setButtonLoading(false);
    };


    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default behavior of the Enter key (form submission)
            if (currentStep === 3) {
                handleSubmit();
            } else {
                nextStep();
            }
        }
    };

    const NumberCounter = ({count, onIncrement, onDecrement}) => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Buttoni onClick={onDecrement}>-</Buttoni>
                <div style={{margin: '0 10px'}}>{count}</div>
                <Buttoni onClick={onIncrement}>+</Buttoni>
            </div>
        );
    };

    useEffect(() => {
        api.checkFormFilled(projectId).then((res) => {
            if (res.data[0]) {
                setAlreadyFilled(true)
                setCurrentStep(6)
                setBrandName(res.data[0].brandName);
                setBrandWebsite(res.data[0].brandWebsite);
                setMissionStatement(res.data[0].missionStatement);
                setTargetAudience(res.data[0].targetAudience);
                setSlugLine(res.data[0].slugLine);
                setUsp(res.data[0].usp);
            }
        });
    }, [
        projectId,
        close,
    ]);

    return (
        <>
            {show ? (
                <ModalContainer onKeyDown={handleKeyDown} tabIndex="0">
                    <Modal>
                        <ModalHeader>
                            <div>

                                {alreadyFilled ? (
                                    <h2 className="modal_header-title">
                                        Fill to Create a New Campaign
                                    </h2>
                                ) : (
                                    <h2 className="modal_header-title">
                                        Fill the details to generate the calendar
                                    </h2>
                                )}

                                {alreadyFilled ? (
                                    <>
                                        <h2 className="modal_header-title">
                                            Step {currentStep === 6 ? 1 : 2} of 2
                                        </h2>
                                        <progress
                                            value={currentStep === 6 ? 1 : currentStep}
                                            max="2"
                                            style={{width: "100%"}}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <h2 className="modal_header-title">
                                            Step {currentStep} of 7
                                        </h2>
                                        <progress
                                            value={currentStep}
                                            max="7"
                                            style={{width: "100%"}}
                                        />
                                    </>
                                )}
                            </div>
                            <CloseButton onClick={() => {
                                setBrandName("");
                                setDay("");
                                setBrandWebsite("");
                                setMissionStatement("");
                                setTargetAudience("");
                                setSlugLine("");
                                setUsp("");
                                setStaticPost("");
                                setShortVideo("");
                                setCarouselPost("");
                                setGif("");
                                setCustomerTestimonial("");
                                setStartDate("");
                                setEndDate("");
                                setCurrentStep(1);
                                close();
                            }}>
                                <IoIosCloseCircleOutline/>
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <InputWrapper>
                                {currentStep === 1 && (
                                    <>
                                        <h2 className="modal_header-title">
                                            Fill the details to generate the calendar
                                        </h2>
                                        <TextInput type='text' placeholder='Zomato India'
                                                   label="What is the brand name for which you are planning the Social Media Calendar?"
                                                   hidePlaceOnHover={true}
                                                   value={brandName}
                                                   onChange={(e) => {
                                                       setBrandName(e.target.value)
                                                   }}/>
                                    </>
                                )}
                                {currentStep === 2 && (
                                    <>
                                        <TextInput type='text'
                                                   placeholder='https://www.zomato.com/india'
                                                   hidePlaceOnHover={true}
                                                   value={brandWebsite}
                                                   label="What is the website of this brand?"
                                                   onChange={(e) => {
                                                       setBrandWebsite(e.target.value)
                                                   }}/>
                                    </>
                                )}
                                {currentStep === 3 && (
                                    <>
                                        <TextInput type='text'
                                                   placeholder='Better food for more people'
                                                   hidePlaceOnHover={true}
                                                   label="What is the Slug line / slogan / mission statemen of this brand?"
                                                   value={slugLine}
                                                   onChange={(e) => {
                                                       setSlugLine(e.target.value)
                                                       setMissionStatement(e.target.value)
                                                   }}/>
                                    </>
                                )}
                                {currentStep === 4 && (
                                    <>
                                        <TextInput type='text'
                                                   placeholder='The consumers of Zomato are between 18-35 years.'
                                                   hidePlaceOnHover={true}
                                                   label="Who are the target audience of your brand?"
                                                   value={targetAudience}
                                                   onChange={(e) => {
                                                       setTargetAudience(e.target.value)
                                                   }}/>
                                    </>
                                )}
                                {currentStep === 5 && (
                                    <>
                                        <TextInput type='textarea'
                                                   placeholder='people between the ages of 18 and 35 who are tech-friendly.'
                                                   hidePlaceOnHover={true}
                                                   label="What is the Unique Selling Proposition of your brand ?"
                                                   value={usp}
                                                   onChange={(e) => {
                                                       setUsp(e.target.value)
                                                   }}/>
                                    </>
                                )}
                                {currentStep === 6 && (
                                    <>
                                        <h4>
                                            What all activities would you like to plan in a typical month?
                                        </h4>
                                        <TextInput type='checkbox'
                                                   placeholder='3'
                                                   hidePlaceOnHover={true}
                                                   label="Static Post"
                                                   value={"1"}
                                                   onChange={(e) => {
                                                       setStaticPost(e.target.value)
                                                   }}/>

                                        <TextInput type='checkbox'
                                                   placeholder='2'
                                                   hidePlaceOnHover={true}
                                                   label="Short Video"
                                                   value={"1"}
                                                   onChange={(e) => {
                                                       setShortVideo(e.target.value)
                                                   }}/>
                                        <TextInput type='checkbox'
                                                   placeholder='1'
                                                   hidePlaceOnHover={true}
                                                   label="Carousel Post"
                                                   value='1'
                                                   onChange={(e) => {
                                                       setCarouselPost(e.target.value)
                                                   }}/>
                                        <TextInput type='checkbox'
                                                   placeholder='2'
                                                   hidePlaceOnHover={true}
                                                   label="GIF"
                                                   value="1"
                                                   onChange={(e) => {
                                                       setGif(e.target.value)
                                                   }}/>
                                        <TextInput type='checkbox'
                                                   placeholder='5'
                                                   value={"1"}
                                                   hidePlaceOnHover={true}
                                                   label="Customer Testimonial"
                                                   onChange={(e) => {
                                                       setCustomerTestimonial(e.target.value)
                                                   }}/>

                                        <DefaultSelect placeholder='Live Video, Stories, Reels, etc' m
                                                       label='Choose Additional Activities'
                                                       hidePlaceOnHover={true}
                                                       onSelect={(selectedOption) => {
                                                           setAdditionalActivities(selectedOption.map((item) => item.value).join(","))
                                                       }}
                                                       isMulti={true}
                                                       options={postActivities}
                                        />
                                    </>
                                )}
                                {currentStep === 7 && (
                                    <>
                                        <TextInput type='calendar'
                                                   placeholder='2024-01-01'
                                                   hidePlaceOnHover={true}
                                                   label="Start Date"
                                                   value={startDate}
                                                   onChange={(e) => {
                                                       setStartDate(e.target.value)
                                                   }}/>

                                        <>
                                            <h4>How many days of content do you want to plan?</h4>
                                            <NumberCounter count={day} onIncrement={() => {
                                                if (day < 31) {
                                                    setDay(day + 1)
                                                }
                                            }} onDecrement={() => {
                                                if (day >= 1) {
                                                    setDay(day - 1)
                                                }
                                                console.log("Welcome ")

                                            }}/>
                                        </>
                                    </>
                                )}
                            </InputWrapper>
                        </ModalBody>
                        <ModalFooter>
                            {currentStep !== 1 && !alreadyFilled && (
                                <Buttoni onClick={prevStep}>Previous</Buttoni>
                            )}


                            {
                                currentStep !== 6 && alreadyFilled && (
                                    <Buttoni onClick={prevStep}>Previous</Buttoni>
                                )
                            }

                            {currentStep === 7 ? (
                                <Buttoni onClick={handleSubmit} disabled={buttonLoading}>
                                    {buttonLoading ? <>Filling...</> : <>Fill Calendar</>}
                                </Buttoni>
                            ) : (
                                <Buttoni onClick={nextStep} disabled={buttonLoading}>
                                    {buttonLoading ? <>Filling...</> : <>Next</>}
                                </Buttoni>
                            )}
                        </ModalFooter>
                    </Modal>
                </ModalContainer>
            ) : null}
        </>
    );
};

export default AiGenerateCalendar;
