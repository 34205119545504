import {
    CloseButton,
    InputWrapper,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader
} from "../../../Components/UI/modal.style";
import axios from "axios";
import {toast} from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {IoIosCloseCircleOutline} from "react-icons/io";
import TextInput from "../../../Components/custome-tags/TextInput/textInput";
import DefaultSelect from "../../../Components/default-select/default-select";
import FileUpload from "../../../Components/FileInput/file-input";
import EmailRoleForm from "../../../Components/UserRoleForm/UserRoleForm";
import {addBulkMembersInOrganisation} from "../../../api";
import {useNavigate} from "react-router-dom";
import industriesObjects from "../../../constants/industries";
import PrimaryButton from "../../../Components/Buttons/Primary-Button/primary-button";
import {containsOnlySpaces, isValidEmail} from "../../../Components/Helpers/helper";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

const CreateOrg = ({show, close, organisation}) => {
    const [step, setStep] = useState(1);
    const [name, setName] = useState("");
    const [organistionId, setOrganistionId] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState("");
    const [email, setEmail] = useState("")
    const [loding, setLoading] = useState(false);
    const [selectedOrgType, setSelectedOrgType] = useState('');
    const navigate = useNavigate();
    const [key, setKey] = useState(0)


    const rolesOptions = [
        {
            value: "member",
            label: "User",
            explanation: "User Can access and use the core features of the platform. - Typically, users can't access administrative or moderation functions."
        },
        {
            value: "moderator",
            label: "Moderator",
            explanation: "Moderator Can add and remove users."
        },
        {
            value: "owner",
            label: "Admin",
            explanation: "Admin has Full control over the entire platform. - User management, including the ability to add, modify, or remove users."
        },
    ]


    const handelOrganisationSelect = (selectedOption) => {
        console.log(selectedOption)
        setSelectedOrgType(selectedOption);
    };


    const handleFilesSelected = (files) => {
        if (files[0]?.size > 50 * 1024 * 1024) {
            setKey(prevKey => prevKey + 1);
            return toast.error('File size exceeds 50 MB. Please choose a smaller file.');
        }
        setFile(files[0]);
    };

    useEffect(() => {
        if (organisation) {
            setName(organisation?.name);
            setDescription(organisation?.description);
            setEmail(organisation?.organisationEmail);
            setLoading(false);
            setStep(1)
            setOrganistionId(organisation._id)
        } else {
            setName("");
            setDescription("");
            setEmail("");
            setFile("");
            setLoading(false);
            setStep(1)
            setOrganistionId("")
        }
    }, [show]);

    const inputChecks = (update) => {
        if (name === "" || containsOnlySpaces(name)) {
            toast.error("Name is required");
            return true;
        } else if (description === "" || containsOnlySpaces(description)) {
            toast.error("Description is required");
            return true;
        } else if (email === "" || !isValidEmail(email)) {
            toast.error("Enter a valid Email");
            return true;
        }
        if (!update) {
            if (selectedOrgType === "") {
                toast.error("Organisation type is required");
                return true;
            } else if (file === "") {
                toast.error("Logo is required");
                return true;
            }
        }
        return false
    }

    const createOrganisations = async () => {

        if (organisation) {
            const formData = new FormData();
            formData.append("name", name);
            formData.append("description", description);
            formData.append("organisationEmail", email);
            formData.append("logo", file)
            formData.append("organisationType", selectedOrgType.value);
            if (inputChecks(true)) {
                return;
            }
            setLoading(true);
            try {
                await axios.put(
                    `${process.env.REACT_APP_API_URL}organisation/${organisation._id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        }
                    }
                ).then((res) => {
                    if (res.data) {
                        console.log("hello")
                        setOrganistionId(res.data._id)
                        close();
                    }
                });

                toast.success("Organization Updated Successfully !");
                setName("");
                setDescription("");
                setEmail("");
                setFile("");
                setSelectedOrgType("")
            } catch (e) {
                toast.error("Something went wrong")
                formData.clear();
            }
            return;
        }
        if (inputChecks(false)) {
            return;
        }
        const formData = new FormData();
        formData.append("name", name);
        formData.append("description", description);
        formData.append("organisationEmail", email);
        formData.append("logo", file)
        formData.append("organisationType", selectedOrgType.value);

        setLoading(true);
        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}organisation`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                }
            ).then((res) => {
                if (res.data) {
                    console.log("hello")
                    setOrganistionId(res.data._id)
                    setStep(2)
                }
            });

            toast.success("Organization Created Successfully !");
            setName("");
            setDescription("");
            setEmail("");
            setFile("");
            setSelectedOrgType("")
        } catch (e) {
            toast.error("Something went wrong")
            formData.clear();
        }
    };


    const AddMultipleMembers = async (users) => {
        if (!users) {
            return close();
        }
        const addMultiple = addBulkMembersInOrganisation(organistionId, users)

        const response = await addMultiple;
        if (response) {
            toast.success("Members Added Successfully !");
            navigate(`/menu/project/${organistionId}`);
            close();
            setStep(1)
        } else {
            toast.error("Something went wrong");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default behavior of the Enter key (form submission)
            createOrganisations();
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <ModalHeader>
                            {
                                organisation ?
                                    (<h1 className="modal_header-title">Settings for {organisation?.name}</h1>)
                                    :
                                    (<h1 className="modal_header-title">Create Organisation</h1>
                                    )
                            }
                            <CloseButton onClick={() => close()}>
                                <IoIosCloseCircleOutline/>
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody onKeyDown={handleKeyDown} tabIndex="0">
                            <InputWrapper style={{width: '100%'}}>
                                <TextInput type='text' placeholder='Organisation Name' value={name}
                                           onChange={e => setName(e.target.value)}/>
                                <TextInput placeholder='Organisation Description' value={description}
                                           type='text'
                                           onChange={e => setDescription(e.target.value)}/>
                                <div>
                                    <TextInput placeholder='Organisation Email' value={email}
                                               type='email'
                                               onChange={e => setEmail(e.target.value)}/>
                                </div>

                                {
                                    organisation ? null : (
                                        <div style={{
                                            marginTop: '8px'
                                        }}>
                                            <DefaultSelect allowCustomValue={true} onSelect={handelOrganisationSelect}
                                                           placeholder={"Choose Organisation Type "}
                                                           options={industriesObjects}
                                            />
                                        </div>
                                    )
                                }
                                <div>
                                    {
                                        organisation ?
                                            (
                                                <h4>
                                                    Update Organisation Logo
                                                </h4>
                                            )
                                            : (
                                                <h4>
                                                    Upload Organisation Logo
                                                </h4>
                                            )
                                    }
                                    <FileUpload
                                        key={key}
                                        onFilesSelected={handleFilesSelected}
                                        width="400px"
                                        height="100px"
                                        borderColor="black"
                                        allowedTypes={["image/png", "image/jpeg"]}
                                    />

                                </div>
                            </InputWrapper>
                        </ModalBody>
                        <ModalFooter>

                            {
                                organisation ?
                                    (
                                        <Button onClick={createOrganisations} loading={loding}>
                                            Update
                                        </Button>
                                    )
                                    :
                                    (
                                        <Button onClick={createOrganisations} loading={loding}>
                                            Create
                                        </Button>
                                    )
                            }
                        </ModalFooter>
                    </>
                );
            case 2:
                return (
                    <>
                        <ModalHeader>
                            <h1 className="modal_header-title">Add Members to Organisation</h1>
                            <CloseButton onClick={() => close()}>
                                <IoIosCloseCircleOutline/>
                            </CloseButton>
                        </ModalHeader>
                        <ModalBody>
                            <EmailRoleForm roles={rolesOptions} onSubmit={users => AddMultipleMembers(users)}/>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {
                show ?
                    <ModalContainer>
                        <Modal>
                            {renderStepContent()}
                        </Modal>
                    </ModalContainer>
                    : null
            }
        </>
    );
}
export default CreateOrg
