import axios from "axios";
import {toast} from "react-hot-toast";

const apiUrl = process.env.REACT_APP_API_URL;

export const sendOtp = async (email) => {
    try {
        const response = await axios.post(`${apiUrl}auth/login-with-otp`, {email});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const verifyOtp = async (email, otp) => {
    try {
        const response = await axios.post(`${apiUrl}auth/verify-otp`, {email, otp});
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const signUp = async (data) => {
    try {
        const response = await axios.post(`${apiUrl}auth/signup`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const fetchOrganisations = async () => {
    try {
        const response = await axios.get(`${apiUrl}organisation`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// API function to fetch projects
export const fetchProjects = async () => {
    try {
        const response = await axios.get(`${apiUrl}project/user-projects`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchChat = async (projectId) => {
    try {
        const response = await axios.get(`${apiUrl}chat/${projectId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const deleteProject = async (projectId, organisationId) => {
    try {
        const response = await axios.delete(`${apiUrl}project/${projectId}?organisationId=${organisationId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
}


export const fetchUserProjectById = async (id) => {
    try {
        const response = await axios.get(`${apiUrl}project/user-project/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const addBulkMembersInOrganisation = async (organisationId, members: []) => {
    try {
        const response = await axios.post(`${apiUrl}organisation/user/addBulk/${organisationId}`, {
            members
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const removeCreativeFromProject = async (creativeId) => {
    try {
        const response = await axios.delete(`${apiUrl}creative/${creativeId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// export const Extract = async (creativeId) => {
//     try {
//         const response = await axios.get(`${apiUrl}creative/extract-text/${creativeId}`, {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//         });
//         console.log(response.data);
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };


export const ReWriteCaption = async (creativeId, mutationType) => {
    try {
        const response = await axios.get(`${apiUrl}creative/mutate-caption/${creativeId}?mutationType=${mutationType}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const generateCaption = async (creativeId, data) => {
    try {
        const response = await axios.post(`${apiUrl}creative/generate-caption/${creativeId}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        toast.error('Something went wrong');
    }
};


export const getCalendarDatesWithHoliday = async (date = 1) => {
    try {
        return await axios.get(`${apiUrl}calendar/calenderWithHolidaysByMonth/${date}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        throw error;
    }
};


export const getCalendarDatesWithPostHolidays = async (projectId, date = 1) => {
    try {
        return await axios.get(`${apiUrl}calendar/calenderWithHolidaysAndPost/?projectId=${projectId}&month=${date}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        throw error;
    }
};

export const createPostWithCalendarIdProjectId = async (data) => {
    try {
        return await axios.post(`${apiUrl}posts/create`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error.response;
    }
};

export const FillCalendarWithAiPosts = async (data) => {
    try {
        return await axios.post(`${apiUrl}posts/FillWithAI`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};


export const deletePost = async (postId) => {
    try {
        return await axios.delete(`${apiUrl}posts/delete/${postId}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};

export const updatePost = async (postId, data) => {
    try {
        return await axios.put(`${apiUrl}posts/update/${postId}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};

export const checkFormFilled = async (projectID) => {
    try {
        return await axios.get(`${apiUrl}posts/project/${projectID}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};

export const createSecret = async (data: any) => {
    try {
        return await axios.post(`${apiUrl}passwords/`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};

export const updateSecret = async (secretID, data: any) => {
    try {
        return await axios.put(`${apiUrl}passwords/${secretID}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};


export const getCurrentUser = async () => {
    try {
        return await axios.get(`${apiUrl}users/me`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        });
    } catch (error) {
        return error;
    }
};


export const updateProfilePicture = async (data: {
        displayPicture?: any;
        aboutProfile?: string;
        firstName?: string;
        lastName?: string;
        username?: string;
        video?: string;
        removeImageTrue?: boolean
    }, userId: any) => {
        try {
            return await axios.put(`${apiUrl}users/me/${userId}`, data,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }
                    ,
                }
            )
                ;
        } catch
            (error) {
            return error;
        }
    }
;


const API = {
    sendOtp,
    verifyOtp,
    signUp,
    fetchOrganisations,
    fetchProjects,
    deleteProject,
    fetchUserProjectById,
    addBulkMembersInOrganisation,
    removeCreativeFromProject,
    ReWriteCaption,
    generateCaption,
    getCalendarDatesWithHoliday,
    getCalendarDatesWithPostHolidays,
    createPostWithCalendarIdProjectId,
    FillCalendarWithAiPosts,
    deletePost,
    updatePost,
    checkFormFilled,
    createSecret,
    updateSecret,
    getCurrentUser,
    updateProfilePicture,
};

export default API;
