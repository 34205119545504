import React from 'react';
import styled from 'styled-components';

const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const TooltipText = styled.span`
    visibility: hidden;
    min-width: 120px;
    background-color: #009cdc;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    font-size: 12px;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        border-color: transparent transparent #009cdc transparent;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(60deg);
    }
`;

const TooltipContainerHover = styled(TooltipContainer)`
    display: flex;
    justify-content: start;
    align-items: center;
    &:hover ${TooltipText} {
        visibility: visible;
        opacity: 1;
    }
`;

const Tooltip = ({ text, children,style  }) => {
    return (
        <TooltipContainerHover>
            {children}
            <TooltipText>{text}</TooltipText>
        </TooltipContainerHover>
    );
};

export default Tooltip;
