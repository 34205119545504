import styled from "styled-components";

export const NavBarWrapper = styled.div`
    width: 100%;
    height: 8%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid #e0e0e0;
    background: white;
`;

export const NavBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
`;


export const BodyContainer = styled.div`
    width: 100%;
    height: 92%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
`;


export const MainBody = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background: white;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;


export const NotificationContainer = styled.div`
    width: calc(100% - 20px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding: 0 10px 50px 10px;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
`;

export const NotificationHeader = styled.div`
    width: 100%;
    justify-content: flex-start;
`;

export const NotificationBox = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    font-size: 20px;
    margin: 10px;
    background-color: rgba(0, 0, 0, .08);
    border-radius: 12px;

    .notification-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        margin-left: 15px;
    }

    p {
        font-size: 16px;
    }

    small {
        font-size: 10px;
        color: rgba(0, 0, 0, .7);
    }
`;


export const UserDetailsUserName = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: white;
    margin: 0 0 0 15px;
`;






