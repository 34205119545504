import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {removeDomainNameFromEmail} from "../../../Components/Helpers/helper";
import AddMember, {roles} from "../addMemberModal/addMember";
import {toast} from "react-hot-toast";
import {
    CloseButton,
    Modal,
    ModalBody,
    ModalContainer,
    ModalFooter,
    ModalHeader
} from "../../../Components/UI/modal.style";
import {IoIosCloseCircleOutline} from "react-icons/io";
import {Buttoni} from "../board.style";
import UserInfoCard from "../../../Components/user-info-card/user-info-card";
import DefaultSelect from "../../../Components/default-select/default-select";
import {Box, Button, Drawer} from "@mui/joy";
import Divider from "@mui/joy/Divider";
import Typography from "@mui/joy/Typography";
import {AiOutlineUserAdd} from "react-icons/ai";


const SlideBar = ({role, project, open, close}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [projectMembers, setProjectMembers] = useState([]);
    const [addMemberModal, setAddMemberModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [editRoleModal, setEditRoleModal] = useState(false)
    const [newRole, setNewRole] = useState('');
    const [memberData, setMemberData] = useState('');
    const menuRef = useRef(null);
    const addMemberToggle = () => setAddMemberModal(!addMemberModal);
    const params = useParams();
    const [loader, setLoader] = useState(false)
    const toggleMenu = () => {
        close(!open)
    }
    const [activeCard, setActiveCard] = useState(null);

    const fetchProjectMembers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}project/users/${params.id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            setProjectMembers(response.data);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const removeMember = async (id) => {
        if (loader) {
            return
        }
        setLoader(true)
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}project/removeUser/${params.id}`, {
                userId: id
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            toast.success("Member removed successfully");
            setAlertModal(false);
            setLoader(false)
            fetchProjectMembers();
        } catch (error) {
            setLoader(false)
            toast.error("Something went wrong");
        }
    }

    const getRole = (role) => {
        return roles?.find(role => role.value = roles?.find(role => role.value = role))
    }

    const editMemberRole = async (id) => {
        if (loader) {
            return
        }
        setLoader(true)
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}project/updateUser/${params.id}`, {
                userId: id, role: newRole
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            toast.success("Member Updated successfully");
            setEditRoleModal(false);
            setLoader(false)
            fetchProjectMembers();
        } catch (error) {
            setLoader(false)
            toast.error("Something went wrong");
        }
    }

    useEffect(() => {
        fetchProjectMembers();
    }, [open, addMemberModal]);

    return (<>
        <Drawer open={open} onClose={toggleMenu} size="sm" anchor="right" ref={menuRef}>
            {/* Menu Content Container */}
            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Box
                    sx={{
                        height: '5%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        level='title-md'
                    >
                        Project Members
                    </Typography>
                    <Divider/>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        p: 2,
                    }}
                >
                    {projectMembers.map((item) => (
                        <UserInfoCard
                            key={item._id}
                            user={item}
                            cardRole={item.role === "admin" ? "Product Owner" : item.role}
                            canRemove={role === 'admin' && item.role !== 'admin'}
                            onRemove={() => {
                                setAlertModal(true);
                                setMemberData(item);
                            }}
                            onEdit={() => {
                                setMemberData(item);
                                setEditRoleModal(true);
                            }}
                            activeCard={activeCard}
                            setActiveCard={setActiveCard}
                        />
                    ))}
                </Box>

                {/* Footer: Add User Button */}
                {role === 'admin' && (
                    <Box
                        sx={{
                            height: '5%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            size="sm"
                            startDecorator={<AiOutlineUserAdd/>}
                            variant="outlined"
                            color="warning"
                            onClick={addMemberToggle}
                        >
                            Add Project Member
                        </Button>
                    </Box>
                )}
            </Box>
        </Drawer>
        <AddMember show={addMemberModal} close={addMemberToggle} project={project}/>
        {alertModal ? <ModalContainer>
            <Modal>
                <ModalHeader>
                    <h3 className="modal_header-title">Remove <b>{removeDomainNameFromEmail(memberData.email)}</b>
                    </h3>
                    <CloseButton
                        onClick={() => setAlertModal(false)}><IoIosCloseCircleOutline/></CloseButton>
                </ModalHeader>
                <ModalBody>
                    <p>Removing a member from the project</p>
                    <p>Email : {memberData.email}</p>
                </ModalBody>
                <ModalFooter>
                    <Buttoni onClick={() => removeMember(memberData._id)}>Remove</Buttoni>
                </ModalFooter>
            </Modal>
        </ModalContainer> : null}
        {editRoleModal ? <ModalContainer>
            <Modal style={{height: 'auto'}}>
                <ModalHeader>
                    <h3 className="modal_header-title">Edit <b>{removeDomainNameFromEmail(memberData.email)} </b>
                        Role in the project
                    </h3>
                    <CloseButton
                        onClick={() => {
                            setEditRoleModal(false)
                            setNewRole(null)
                        }}><IoIosCloseCircleOutline/></CloseButton>
                </ModalHeader>
                <ModalBody>
                    <p>Email : {memberData.email}</p>
                    <DefaultSelect label={'Role'} selectedValue={getRole(memberData.role)}
                                   onSelect={(selectedOption) => {
                                       setNewRole(selectedOption.value)
                                   }}
                                   placeholder={'Select Member Role'}
                                   options={roles}
                    />
                </ModalBody>
                <ModalFooter>
                    <Buttoni onClick={() => editMemberRole(memberData._id)}>Update</Buttoni>
                </ModalFooter>
            </Modal>
        </ModalContainer> : null}
    </>)

}
export default SlideBar;
