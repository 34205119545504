import styled from "styled-components";

export const LabelTag = styled.label`
  position: relative;
  width: 100%;

  span {
    position: absolute;
    left: 0;
    top: 0;
    padding: calc(.5rem * 0.75) calc(.5rem * 0.5);
    margin: calc(.5rem * 0.75 + 3px) calc(.5rem * 0.5);
    white-space: nowrap;
    transform: translate(0, 35%);
    transform-origin: 0 0;
    background: white;
    transition: transform 120ms ease-in, color 120ms ease-in;
    line-height: 1;
    color: rgba(0, 0, 0, 0.6);
    pointer-events: none; /* Ensure the span doesn't block input interaction */
  }

  input, textarea {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid #cccccc;
    padding: calc(.5rem * 1.5) .5rem;
    color: black;
    background: transparent;
    border-radius: 4px;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    -webkit-appearance: none; /* Reset appearance for consistency */
    -moz-appearance: none;
    appearance: none;
    outline: none; /* Remove default outline in all browsers */
    
    &:focus, &:not(:placeholder-shown) {
      & + span {
        transform: translate(0.25rem, -35%) scale(0.8);
        color: rgb(61, 31, 119);
        display: ${props => props.hidePlaceOnHover ? 'none' : 'block'};
      }
    }
  }

  textarea {
    height: 300px;
    resize: none;
    margin-bottom: 1rem;

    &:focus, &:not(:placeholder-shown) {
      & + span {
        transform: translate(0.25rem, -45%) scale(0.8);
      }
    }
  }
`;
