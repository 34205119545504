const postActivities = [
    {
        value: "Reels",
        label: "Reels",
    }
    ,
    {
        value: "Long Videos",
        label: "Long Videos",
    }
    ,
    {
        value: "Live Videos",
        label: "Live Videos",
    }
    ,
    {
        value: "Polls",
        label: "Polls",
    }
    ,
    {
        value: "Contests/Giveaways",
        label: "Contests/Giveaways",
    }
    ,
    {
        value: "Behind-the-Scenes Content",
        label: "Behind-the-Scenes Content",
    }
    ,
    {
        value: "User-Generated Content",
        label: "User-Generated Content",
    }
    ,
    {
        value: "Influencer Collaborations",
        label: "Influencer Collaborations",
    }
    ,
    {
        value: "Infographics",
        label: "Infographics",
    }
    ,
    {
        value: "Memes",
        label: "Memes",
    }
    ,
    {
        value: "Quote Graphics",
        label: "Quote Graphics",
    }
    ,
    {
        value: "Product Showcases",
        label: "Product Showcases",
    }
    ,
    {
        value: "Tutorials/DIYs",
        label: "Tutorials/DIYs",
    }
    ,
    {
        value: "Customer Testimonials",
        label: "Customer Testimonials",
    }
    ,
    {
        value: "Events/Announcements",
        label: "Events/Announcements",
    }
    ,
    {
        value: "Holiday/Seasonal Content",
        label: "Holiday/Seasonal Content",
    }
    ,
    {
        value: "Case Studies",
        label: "Case Studies",
    }
    ,
    {
        value: "Interactive Quizzes/Polls",
        label: "Interactive Quizzes/Polls",
    }
    ,
    {
        value: "Customer Reviews/Testimonials",
        label: "Customer Reviews/Testimonials",
    }
    ,
    {
        value: "FAQ Sessions",
        label: "FAQ Sessions",
    }
    ,
    {
        value: "Influencer Takeovers",
        label: "Influencer Takeovers",
    }
    ,
    {
        value: "Employee Spotlights",
        label: "Employee Spotlights",
    }
    ,
    {
        value: "Infotainment (Informative + Entertaining) Content",
        label: "Infotainment (Informative + Entertaining) Content",
    }
    ,
    {
        value: "Flash Sales/Exclusive Offers",
        label: "Flash Sales/Exclusive Offers",
    }
    ,
    {
        value: "Challenges (e.g., fitness, cooking, creative)",
        label: "Challenges (e.g., fitness, cooking, creative)",
    }
    ,
    {
        value: "Virtual Tours",
        label: "Virtual Tours",
    }
    ,
    {
        value: "Throwback Thursday (TBT) Posts",
        label: "Throwback Thursday (TBT) Posts",
    }
    ,
    {
        value: "Before-and-After Transformations",
        label: "Before-and-After Transformations",
    }
    ,
    {
        value: "Fan Art/Creations",
        label: "Fan Art/Creations",
    }
    ,
    {
        value: "Inspirational Stories",
        label: "Inspirational Stories",
    }
    ,
    {
        value: "Fan Shoutouts/Appreciation Posts",
        label: "Fan Shoutouts/Appreciation Posts",
    }
    ,
    {
        value: "Day-in-the-Life Content",
        label: "Day-in-the-Life Content",
    }
    ,
    {
        value: "Educational Series",
        label: "Educational Series",
    }
    ,
    {
        value: "Virtual Events/Webinars",
        label: "Virtual Events/Webinars",
    }
    ,
    {
        value: "Community Spotlights",
        label: "Community Spotlights",
    }
    ,
    {
        value: "Brand Milestones/Celebrations",
        label: "Brand Milestones/Celebrations",
    }
]

export default postActivities;
