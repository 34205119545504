import React from 'react';
import styled, {keyframes} from "styled-components";


const shimmer = keyframes`
  0% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
`;

const ParentHolder = styled.div`
  background: linear-gradient(90deg, #f1f1f1 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.0s infinite;
  margin: 10px;
  border-radius: 25px;
  border-bottom: 1px solid #ddd;
    &:last-child {
        border-bottom: none;
    }
`;


const CardSkeletonLoader = ({number,width,heights}) => {

    return (
        <>
            {
                [...Array(number)].map((e, i) => (
                    <ParentHolder key={i} style={{width: width, height: heights}}>
                    </ParentHolder>
                ))
            }
        </>
    );

};

export default CardSkeletonLoader;
