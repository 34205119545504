export function downloadFile(url) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function (e) {
        if (this.status === 200) {
            var blob = this.response;
            var link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = url.substring(url.lastIndexOf("/") + 1);
            link.click();
        }
    };
    xhr.send();
}


export function extractBaseUrl(url) {
    const urlObject = new URL(url);
    return urlObject.origin + urlObject.pathname;
}

export function isVideo(url) {
    let pattern = /\.(mp4|ogg|webm|mov|flv|avi|wmv|mpeg|mpg)$/i;


    try {
        const extractUrl = extractBaseUrl(url);
        if (extractUrl === null) {
            return pattern.test(url);
        }
        return pattern.test(extractUrl);
    } catch (e) {
        return pattern.test(url);
    }

    // Regular expression pattern to match video file extensions
}

// export function formatDate(dateString, separator = '-') {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const year = date.getFullYear();
//     const time = date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
//     return `${day}${separator}${month}${separator}${year} ${time.toUpperCase()}`;
// }


export function removeDomainNameFromEmail(email) {
    return email.substring(0, email.indexOf('@')).replace(/[0-9]/g, '');
    // return email.substring(0, email.indexOf('@'));
}

export function extractInitialsFromEmail(email) {

    if (email === null || email === undefined) {
        return '';
    }

    const fullName = removeDomainNameFromEmail(email);
    return fullName.substring(0, 1).toUpperCase();
}

export function hasOnlySpace(text) {
    return /^\s+$/.test(text);
}

export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}


export function extractNameFromEmail(email) {

    if (email === null || email === undefined) {
        return '';
    }

    const fullName = removeDomainNameFromEmail(email);
    const fullNameWithoutNumbers = fullName.replace(/[0-9]/g, ''); // remove all numbers
    const firstName = fullNameWithoutNumbers.substring(0, 9).toUpperCase();
    const lastName = fullNameWithoutNumbers.substring(9).toUpperCase();
    return firstName + ' ' + lastName;
}

export function Back() {
    window.history.back();
}

export const containsOnlySpaces = (str) => {
    const regex = /^\s+$/;
    return regex.test(str);
};
export const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};


export function formatDate(isoString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(isoString);

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date.getMinutes();

    let formattedHours = hours;
    let period = 'AM';

    if (hours >= 12) {
        period = 'PM';
        if (hours > 12) {
            formattedHours = hours - 12;
        }
    } else if (hours === 0) {
        formattedHours = 12;
    }

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}-${month} ${formattedHours}:${formattedMinutes} ${period}`;
}


export const formatMonthYear = (date) => {
    const d = new Date(date);
    return `${d.getMonth() + 1}-${d.getFullYear()}`;
};

// Group videos by month-year of creation time


export const monthDateToMonthName = (monthDate) => {
    const [month, year] = monthDate.split('-');
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'];
    return `${months[month - 1]} ${year}`;
};


export function saveCurrentMonth() {
    const currentDate = new Date();
    const monthName = currentDate.toLocaleString('default', {month: 'long'});
    const monthNumber = currentDate.getMonth() + 1; // Adding 1 because months are zero-based

    return {
        monthName: monthName,
        monthNumber: monthNumber
    };
}

export function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    }
    return str;
}

// create a function to that will change SUBHADEEP DATTA to Subhadeep Datta
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
