import styled from "styled-components";

export const MemberCapsule = styled.div`
  width: 100%;
  div{
    width: 45%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
      img{
          width: 30px;
          height: 30px;
      }
  }
  img{
    margin-left: 5px;
    cursor: pointer;
  }
  p{
    margin: 0;
  }
`;