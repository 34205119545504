const PostCategoryArray = [
    {
        value: 'static',
        label: 'Static',
        explanation: "Static social media posts lack movement and aren't interactive. Photos, infographics, memes and screenshots are common examples of static social posts.",
    },
    {
        value: 'video',
        label: 'Video',
        explanation: "Video posts are a great way to engage your audience. They can be used to educate, entertain, or inspire your audience.",
    },
    {
        value: "short video",
        label: "Short Video",
        explanation: "Short videos are a great way to engage your audience. They can be used to educate, entertain, or inspire your audience.",
    },
    {
        value: 'gif',
        label: 'GIF',
        explanation: "GIFs are a great way to add some fun to your social media posts. They can be used to educate, entertain, or inspire your audience.",
    },
    {
        value: "carousel",
        label: "Carousel",
        explanation: "Carousel posts are a great way to share multiple images or videos in a single post. They can be used to educate, entertain, or inspire your audience.",
    },
    {
        value: "customer_testimonial",
        label: "Customer Testimonial",
        explanation: "Customer testimonials are a great way to build trust with your audience. They can be used to educate, entertain, or inspire your audience.",
    },
    {
        value: "poll",
        label: "Poll",
        explanation: "Polls are a great way to engage your audience. They can be used to educate, entertain, or inspire your audience.",
    }
]


export default PostCategoryArray;
