import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./store/store";
import LogRocket from "logrocket";

const root = ReactDOM.createRoot(document.getElementById('root'));


if (process.env.REACT_APP_ENVIROMENT === 'PROD') {
    console.log = () => {
    }
    console.error = () => {
    }
    console.debug = () => {
    }
    console.warn = () => {
    }
    console.info = () => {
    }


    LogRocket.init('55sm9w/sounf');

    LogRocket.identify(localStorage.getItem('userName'), {
        name: localStorage.getItem('userName'),
    });

}


root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
