import styled from "styled-components";


export const ChatContainer = styled.div`
  width: 90%;
  height: 99%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: lavender;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const ChatArea = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: scroll;
  background-color: #f5f5f5;
  border-radius: 10px 10px 0 0;
`;


export const ChatMessage = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    max-width: 70%;
    align-self: ${props => props.isSender ? 'flex-end' : null};
`;


export const ChatInputArea = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
`;


export const ChatInput = styled.textarea`
    flex: 1;
    resize: none;
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #e0e0e0;
    outline: none;
    font-size: 16px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
`;



