import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast, Toaster} from 'react-hot-toast';
import API from '../../api';
import {useDispatch} from 'react-redux';
import {login} from '../../store/slices/authSlice';
import axios from 'axios';
import {containsOnlySpaces, isValidEmail} from '../../Components/Helpers/helper';
import OtpInput from '../../Components/custome-tags/TextInput/otp-input';

import {CssVarsProvider} from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [activeView, setActiveView] = useState("login");
    const isOtpValid = otp.length === 6;

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleOtpChange = (otp) => setOtp(otp);

    const handleSendOtp = async () => {
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email");
            return;
        }
        setLoading(true);
        try {
            const response = await API.sendOtp(email);
            if (response) {
                setActiveView("otp");
                toast.success("OTP sent to your email");
            } else {
                toast.error("Failed to send OTP. Please try again.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    const handelSignUp = async () => {
        if (!isValidEmail(email)) {
            toast.error("Please enter a valid email");
            return;
        }
        setLoading(true);
        try {
            const response = await API.signUp({
                email: email,
            });
            if (response) {
                setActiveView("otp");
                toast.success("OTP sent to your email");
            } else {
                toast.error("Failed to send OTP. Please try again.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const submitOtp = async () => {
        if (!isOtpValid || containsOnlySpaces(otp)) {
            toast.error("Please enter a 6-digit OTP");
            return;
        }
        setLoading(true);
        try {
            const response = await API.verifyOtp(email, otp);
            if (response?.token) {
                localStorage.setItem("token", response.token);
                axios.defaults.headers.common["Authorization"] = `token ${response.token}`;
                localStorage.setItem("userRole", "user");
                localStorage.setItem("userName", response.userName);
                toast.success("Login successful. Redirecting to the dashboard...");

                setTimeout(() => {
                    dispatch(
                        login({
                            token: response.token,
                            userRole: "user",
                            userName: response.userName,
                        })
                    );
                    navigate("/menu/dashboard");
                }, 1000);
            } else {
                toast.error("Login failed. Please check your OTP and try again.");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <CssVarsProvider defaultMode="light" disableTransitionOnChange>
            <CssBaseline/>
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Form-maxWidth': '800px',
                        '--Transition-duration': '0.4s',
                    },
                }}
            />
            <Box
                sx={(theme) => ({
                    width: {xs: '100%', md: '50vw'},
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    backdropFilter: 'blur(12px)',
                    backgroundColor: 'rgba(255 255 255 / 0.2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundColor: 'rgba(19 19 24 / 0.4)',
                    },
                })}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        width: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 3,
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box sx={{gap: 2, display: 'flex', alignItems: 'center'}}>
                            <IconButton variant="soft" color="primary" size="lg">
                                <img style={{
                                    width: '30px',
                                    borderRadius: '50%'
                                }} src='/Fennel1.png' alt={'img'}/>
                            </IconButton>
                            <Typography level="title-lg">
                                Sounf
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .MuiFormLabel-asterisk`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        {activeView === "login" && (
                            <Stack gap={4} sx={{mb: 2}}>
                                <Stack gap={1}>
                                    <Typography component="h1" level="h3">
                                        Sign in with OTP
                                    </Typography>
                                    <Typography level="body-sm">
                                        New to company?{' '}
                                        <Link
                                            onClick={() => setActiveView("signUp")}
                                            level="title-sm"
                                            sx={{cursor: 'pointer'}}
                                        >
                                            Sign up!
                                        </Link>
                                    </Typography>
                                </Stack>
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleSendOtp();
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Email</FormLabel>
                                        <Input type="email" name="email" value={email} onChange={handleEmailChange}/>
                                    </FormControl>
                                    <Stack gap={4} sx={{mt: 2}}>
                                        <Button loading={loading} loadingPosition="start" type="submit" fullWidth>
                                            Send OTP
                                        </Button>
                                    </Stack>
                                </form>
                            </Stack>
                        )}
                        {activeView === "otp" && (
                            <Stack gap={4} sx={{mb: 2}}>
                                <Stack gap={1}>
                                    <Typography component="h1" level="h3">
                                        OTP Verification
                                    </Typography>
                                    <Typography level="body-sm">
                                        Please enter the OTP sent to your email
                                    </Typography>
                                </Stack>
                                <OtpInput
                                    length={6}
                                    onComplete={handleOtpChange}
                                    inputStyle={{
                                        width: "40px",
                                        height: "40px",
                                        margin: "0 5px"
                                    }}
                                />
                                <Button
                                    variant="soft"
                                    color="neutral"
                                    fullWidth
                                    onClick={submitOtp}
                                    loading={loading} loadingPosition="start"
                                >
                                    Verify OTP
                                </Button>
                            </Stack>
                        )}
                        {activeView === "signUp" && (
                            <Stack gap={4} sx={{mb: 2}}>
                                <Stack gap={1}>
                                    <Typography component="h1" level="h3">
                                        Sign Up
                                    </Typography>
                                    <Typography level="body-sm">
                                        Please use your official email to sign up
                                    </Typography>
                                </Stack>
                                <form
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handelSignUp();
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Email</FormLabel>
                                        <Input type="email" name="email" value={email} onChange={handleEmailChange}/>
                                    </FormControl>
                                    <Stack gap={4} sx={{mt: 2}}>
                                        <Button loading={loading} loadingPosition="start" type="submit" fullWidth>
                                            Send OTP
                                        </Button>
                                    </Stack>
                                </form>
                                <Link
                                    onClick={() => setActiveView("login")}
                                    level="title-sm"
                                    sx={{cursor: 'pointer'}}
                                >
                                    Already have an account? Login here.
                                </Link>
                            </Stack>
                        )}
                    </Box>
                    <Box component="footer" sx={{py: 3}}>
                        <Typography level="body-xs" textAlign="center">
                            © Noisiv Consulting {new Date().getFullYear()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={(theme) => ({
                    height: '100%',
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    left: {xs: 0, md: '50vw'},
                    transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    backgroundColor: 'background.level1',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: 'url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)',
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage: 'url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)',
                    },
                })}
            />
            <Toaster position="bottom-center"/>
        </CssVarsProvider>
    );
};

export default Login;
