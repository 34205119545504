import React, {useEffect, useState} from "react";
import {Avatar, Box, Button, Card, CardContent, Typography} from '@mui/joy';
import {IoEyeOutline} from "react-icons/io5";
import {extractInitialsFromEmail, truncateString} from "../Helpers/helper";
import Ellipsis from "../Ellipsis/Ellipsis";

const UserInfoCard = ({
                          user, cardRole, canRemove = false, onRemove = () => {
    }, onEdit = () => {
    }, activeCard, setActiveCard
                      }) => {
    const hoverVisible = activeCard === user.email;
    const [clicked, setClicked] = useState(false);

    const handleMouseLeave = () => {
        if (!clicked) {
            setActiveCard(false);
        }
    };

    const handleClick = () => {
        if (activeCard === user.email) {
            setActiveCard(null);
        } else {
            setClicked(true);
            setActiveCard(user.email);
        }
    };

    const handleHoverCardMouseLeave = () => {
        if (clicked) {
            setActiveCard(false);
            setClicked(false);
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (!event.target.closest(".card-wrapper") && !event.target.closest(".hover-over-card")) {
                setActiveCard(false);
                setClicked(false);
            }
        };

        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    return (
        <Card
            variant="outlined"
            sx={{
                width: 'calc(100% - 20px)',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                position: 'relative',
                margin: '10px 0',
                borderRadius: '12px',
                padding: '5px 10px',
                boxShadow: hoverVisible ? '0 4px 20px rgba(0, 0, 0, 0.1)' : '0 2px 10px rgba(0, 0, 0, 0.05)',
                transition: 'box-shadow 0.3s',
                cursor: 'pointer',
                '&:hover': {
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
            }}
            className="card-wrapper"
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <CardContent
                sx={{
                    marginLeft: '10px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Typography level="body2" sx={{textAlign: 'start', fontWeight: 'bold'}}>
                    {truncateString(
                        user.firstName && user.lastName ? `${user?.firstName} ${user?.lastName}` : user.email,
                        13
                    )}
                </Typography>
                <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Box
                        sx={{
                            fontSize: '12px',
                            color: '#131590',
                            background: '#eef1ff',
                            borderRadius: '10px',
                            padding: '2px 8px',
                            textAlign: 'center',
                            fontWeight: '500',
                        }}
                    >
                        <Typography level="body3" noWrap>
                            {truncateString(cardRole, 10)}
                        </Typography>
                    </Box>
                    <IoEyeOutline style={{color: '#666'}}/>
                </Box>
            </CardContent>
            <Box
                className={`hover-over-card ${hoverVisible ? "visible" : ""}`}
                sx={{
                    display: hoverVisible ? 'flex' : 'none',
                    position: 'absolute',
                    top: '55px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '180px',
                    backgroundColor: '#ffffff',
                    border: '1px solid #e0e0e0',
                    borderRadius: '12px',
                    zIndex: 1000,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                    opacity: hoverVisible ? 1 : 0,
                    transition: 'opacity 0.3s',
                }}
                onMouseLeave={handleHoverCardMouseLeave}
            >
                <Avatar
                    size='lg'
                    src={user?.displayPicture ? `${process.env.REACT_APP_IMAGE_URL}${user.c_displayPicture}` : undefined}
                >
                    {!user?.displayPicture && extractInitialsFromEmail(user.email)}
                </Avatar>
                <Box sx={{textAlign: 'center', marginTop: '10px'}}>
                    <Typography level="h6" sx={{color: "#131590", fontSize: "15px", fontWeight: 'bold'}}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography level="body3" sx={{fontSize: "12px", marginTop: '5px', color: '#666'}}>
                        {user.aboutProfile ? (
                            <>
                                <b>About:</b> &nbsp; {user.aboutProfile}
                            </>
                        ) : (
                            <b>No About</b>
                        )}
                    </Typography>
                    <Typography level="body3" sx={{fontSize: "12px", marginTop: '5px', color: '#666'}}>
                        {user.email ? (
                            <>
                                <b>Email:</b>&nbsp;{truncateString(user.email, 20)}
                            </>
                        ) : (
                            <b>No Email</b>
                        )}
                    </Typography>
                    {canRemove && (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginTop: '15px'
                        }}>
                            <Button
                                color="danger"
                                onClick={onRemove}
                                size={'sm'}
                            >
                                Remove
                            </Button>
                            &nbsp;
                            <Button
                                color="primary"
                                size={'sm'}
                                onClick={onEdit}
                            >
                                Edit Role
                            </Button>
                        </Box>
                    )}
                </Box>
            </Box>
        </Card>
    );
};

export default UserInfoCard;
