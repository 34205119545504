import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleInput = styled.input`
  display: none;
`;
const Slider = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 15px;
  margin: 0 10px;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  ${ToggleInput}:checked + & {
    background-color: #2196f3;
  }

  ${ToggleInput}:checked + &::before {
    transform: translateX(20px);
  }
`;

const Toggle = ({value, change,children} ) => {
    const [isChecked, setIsChecked] = useState();

    useEffect(() => {
        setIsChecked(value)
    }, [value]);

    const handleToggle = () => {
        setIsChecked(!isChecked);
        change(!isChecked);
    };

    return (
        <ToggleContainer onClick={handleToggle}>
            <span>{children}:</span>
            <ToggleInput type="checkbox" checked={isChecked}  />
            <Slider />
        </ToggleContainer>
    );
};

export default Toggle;
