import React, {useEffect, useState} from "react";
import {
    BodyContainer,
    MainBody,
    NavBar,
    NavBarWrapper,
    NotificationBox,
    NotificationContainer,
} from "./notification.style";
import {BreadCrumbsHolder, ScreenWrapper} from "../../Components/UI/basic.style";
import {extractInitialsFromEmail, extractNameFromEmail, formatDate} from "../../Components/Helpers/helper";
import axios from "axios";
import {toast} from "react-hot-toast";
import {ProfileIconContainer, ProfileIconText} from "../../Components/LogoutMenu/logoutMenu.style";
import CardSkeletonLoader from "../../Components/Loaders/Card-Skeleton-Loader";
import SearchBar from "../../Components/Search-Bar/search-bar";
import {Avatar, Breadcrumbs, Typography} from "@mui/joy";
import Link from "@mui/joy/Link";
import {BiHome} from "react-icons/bi";


const Notifications = () => {

    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const fetchNotifications = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}notification/user`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            setLoading(false);
            setNotifications(response.data);
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong");
        }
    };

    const filteredData = notifications?.filter(item =>
        Object.entries(item).some(([key, value]) =>
            (typeof value === 'string' && value.toLowerCase().includes(searchTerm.trim().toLowerCase())) ||
            (key === 'user' && Object.values(value).some(userValue =>
                typeof userValue === 'string' && userValue.toLowerCase().includes(searchTerm.trim().toLowerCase())
            ))
        )
    );

    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <>
            <ScreenWrapper>
                <BreadCrumbsHolder>
                    <Breadcrumbs separator="›" size='sm'>
                        <Link color="primary" href="/">
                            <BiHome/>
                        </Link>
                        <Typography>
                            <Typography>Notifications</Typography>
                        </Typography>
                    </Breadcrumbs>
                </BreadCrumbsHolder>
                <NavBarWrapper>
                    <NavBar style={{marginLeft: '4%'}}>
                        <Typography level="title-lg">Notifications</Typography>
                    </NavBar>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2%', marginBottom: '10px'}}>
                        <SearchBar value={val => setSearchTerm(val)} searchTerm={searchTerm}/>
                    </div>
                </NavBarWrapper>
                <BodyContainer>
                    <MainBody>
                        <NotificationContainer>
                            {loading ? (
                                <CardSkeletonLoader width={"80%"} heights={"100px"} number={5}/>
                            ) : null}
                            {filteredData?.map((notification) => (
                                <NotificationBox key={notification?._id}>
                                    <ProfileIconContainer>
                                        <ProfileIconText>
                                            {
                                                notification.user?.displayPicture ?
                                                    <Avatar
                                                        size='lg'
                                                        src={process.env.REACT_APP_IMAGE_URL + notification.user?.c_displayPicture}
                                                        alt="profile"
                                                        tooltip={notification.user?.firstName + " " + notification.user?.lastName}
                                                    />
                                                    :
                                                    <Avatar
                                                        size='lg'
                                                    >
                                                        {extractInitialsFromEmail(notification?.user?.email)}
                                                    </Avatar>
                                            }
                                        </ProfileIconText>
                                    </ProfileIconContainer>
                                    <div className={'notification-content'}>
                                        <p>
                                            {notification?.user?.firstName ? notification?.user?.firstName + " " + notification?.user?.lastName : extractNameFromEmail(notification?.user?.email).toUpperCase()}
                                            {" "} {notification?.description} {" "} {notification?.creative?.title} {"in"} {notification?.project?.name} </p>
                                        <small>{formatDate(notification?.createTime)}</small>
                                    </div>
                                </NotificationBox>
                            ))}
                        </NotificationContainer>
                    </MainBody>
                </BodyContainer>
            </ScreenWrapper>
        </>
    );
};

export default Notifications;
