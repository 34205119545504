import React, {useEffect} from "react";
import {BreadCrumbsHolder, Header, ScreenWrapper} from "../../Components/UI/basic.style";
import {MainBody} from "../Dashboard/dashboard.style";
import {toast, Toaster} from "react-hot-toast";
import {Back, hasOnlySpace, removeDomainNameFromEmail} from "../../Components/Helpers/helper";
import axios from "axios";
import AddSecretForm from "./AddSecretComponent/add-secret-component";
import {CiVault} from "react-icons/ci";
import {IoAddOutline, IoEye, IoReturnUpBackOutline} from "react-icons/io5";
import SearchBar from "../../Components/Search-Bar/search-bar";
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    DialogActions,
    DialogContent,
    DialogTitle,
    Modal,
    ModalDialog,
    Table,
    Typography
} from "@mui/joy";
import {AiOutlineDelete} from "react-icons/ai";
import Ellipsis from "../../Components/Ellipsis/Ellipsis";
import {PiCopySimpleBold} from "react-icons/pi";
import TableSkeletonLoader from "../../Components/Loaders/Table-skeleton-loader";
import Divider from "@mui/joy/Divider";
import {ImWarning} from "react-icons/im";
import {FiEdit} from "react-icons/fi";
import Link from "@mui/joy/Link";
import {BiHome} from "react-icons/bi";


const PasswordMangerView = () => {

    const [currentRole, setCurrentRole] = React.useState("");
    const [currentOrg, setCurrentOrg] = React.useState("");
    const [secrets, setSecrets] = React.useState([]);
    const [showAddSecretForm, setShowAddSecretForm] = React.useState(false);
    const [existingSecret, setExistingSecret] = React.useState(false);
    const [organisationMembers, setOrganisationMembers] = React.useState([]);
    const [projects, setProjects] = React.useState([]);
    const [currentUserId, setCurrentUserId] = React.useState("");
    const [deleteAlert, setDeleteAlert] = React.useState(false)
    const [deleteID, setDeleteID] = React.useState("")
    const [searchTerm, setSearchTerm] = React.useState("");
    const [loading, setLoading] = React.useState(true)


    const ToggleAddSecretForm = () => {
        setShowAddSecretForm(!showAddSecretForm);
    }

    const filteredData = secrets?.filter(item =>
        Object.values(item)?.some(value =>
            typeof value === 'string' && value.toLowerCase().includes(searchTerm.trim().toLowerCase())
        ) || item?.createdBy?.email?.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );


    const url = window.location.href;
    const urlSplit = url.split("/");
    const params = {
        id: urlSplit[urlSplit.length - 2],
    };


    const getCurrentUserRole = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}organisation/currentUser/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            setCurrentUserId(response.data.userId);
            setCurrentRole(response.data.role);
            setCurrentOrg(response.data.organisation);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }

    const getSecretsWithOrg = async () => {
        setLoading(true)

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}passwords/byOrganisation/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            setLoading(false)
            setSecrets(response.data);
        } catch (error) {
            setLoading(false)
            toast.error("Something went wrong");
        }
    }

    const getPasswordById = async (id) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}passwords/getDecrypted/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            secrets?.filter((secret) => {
                if (secret._id === id) {
                    secret.passwords = response?.data.encryptedPassword;
                    navigator.clipboard.writeText(response?.data.encryptedPassword);
                    toast.success("Password copied to clipboard");
                }
            });

            setSecrets([...secrets]);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    const getOrganisationMembers = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}organisation/users/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            setOrganisationMembers(response.data);
        } catch (error) {
            toast.error("Something went wrong");
        }
    }


    const fetchProjects = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}project/organisation/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );

            setProjects(response.data);

        } catch (error) {
            toast.error("Something went wrong");
        }
    };


    useEffect(() => {
        getCurrentUserRole();
        getSecretsWithOrg();
        getOrganisationMembers();
        fetchProjects();
    }, []);
    return (
        <>
            <>
                <Toaster/>
                <ScreenWrapper>
                    <BreadCrumbsHolder>
                        <Breadcrumbs separator="›" size='sm'>
                            <Link color="primary" href="/">
                                <BiHome/>
                            </Link>
                            <Link color="primary" href="/menu/organisation">
                                <Typography>
                                    <Typography>Organisations</Typography>
                                </Typography>
                            </Link>
                            <Link color="primary" href={`/menu/project/${params.id}`}>
                                <Typography>
                                    <Typography>{currentOrg?.name}</Typography>
                                </Typography>
                            </Link>
                            <Typography>
                                <Typography>Vault</Typography>
                            </Typography>
                        </Breadcrumbs>
                    </BreadCrumbsHolder>
                    <Header>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <h1 style={{
                                fontSize: '24px',
                                fontWeight: '400',
                                color: '#54289a',
                                textAlign: 'center'
                            }}
                            ><CiVault style={{marginRight: '3px'}}/>
                                <Ellipsis>{currentOrg?.name}</Ellipsis>
                                's Vault
                            </h1>
                            <SearchBar searchTerm={searchTerm} value={val => setSearchTerm(val)}/>
                        </div>
                        <IoReturnUpBackOutline onClick={Back} size={30}
                                               style={{marginRight: '10px', cursor: 'pointer'}}/>
                    </Header>
                    <MainBody>
                        <Box style={{
                            margin: '10px 20px 0 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'end'
                        }}>
                            <Button size='sm' startDecorator={<IoAddOutline/>}
                                    onClick={ToggleAddSecretForm}>
                                Add New
                            </Button>
                            {
                                secrets.length > 0 ?
                                    <Table
                                        stickyHeader
                                        variant="soft"
                                        sx={{
                                            '& tr > *:last-child': {
                                                bgcolor: 'var(--TableCell-headBackground)',
                                            },
                                        }}
                                    >
                                        <thead>
                                        <tr>
                                            <th style={{width: '6%'}}>S.No</th>
                                            <th>Created By</th>
                                            <th>User Name</th>
                                            <th>Passwords</th>
                                            <th>Description</th>
                                            <th>Tags</th>
                                            <th>Projects</th>
                                            <th style={{
                                                width: '10%',
                                            }}>Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            loading ? (
                                                <TableSkeletonLoader numCells={8} numRows={8}/>
                                            ) : null
                                        }
                                        {
                                            filteredData?.map((secret, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{removeDomainNameFromEmail(secret?.createdBy?.email)}</td>
                                                        <td>
                                                            <Ellipsis character={200}>
                                                                {secret?.email}
                                                            </Ellipsis>
                                                        </td>
                                                        <td onClick={
                                                            () => {
                                                                if (secret?.passwords) {
                                                                    navigator.clipboard.writeText(secret?.passwords);
                                                                    toast.success("Password copied to clipboard");
                                                                }
                                                            }
                                                        }>
                                                            {
                                                                secret?.passwords ? (<>
                                                                        <Button endDecorator={
                                                                            <PiCopySimpleBold/>
                                                                        } size="sm" variant="soft" color="success">
                                                                            Copy Again
                                                                        </Button>
                                                                    </>
                                                                ) : null
                                                            }


                                                            {
                                                                secret?.passwords ? null :
                                                                    <Button size="sm" variant="soft"
                                                                            startDecorator={<IoEye/>}
                                                                            onClick={() => {
                                                                                getPasswordById(secret._id)
                                                                            }}>
                                                                        Get Password
                                                                    </Button>
                                                            }
                                                        </td>
                                                        <td style={{
                                                            wordBreak: 'break-word',
                                                            overflowWrap: 'break-word',
                                                            maxHeight: '100px'
                                                        }}>
                                                            <Ellipsis>
                                                                {secret?.description}
                                                            </Ellipsis>
                                                        </td>
                                                        <td>
                                                            {secret?.tags?.map((tag, index) => {
                                                                if (!hasOnlySpace(tag)) {
                                                                    return (
                                                                        <Chip
                                                                            key={index}
                                                                            color='primary'
                                                                            size="sm"
                                                                            variant="outlined"
                                                                            sx={{margin: '2px'}}
                                                                        >
                                                                            {tag.replace(/\s/g, '')}
                                                                        </Chip>
                                                                    );
                                                                }
                                                                return null;
                                                            })}
                                                        </td>
                                                        <td>
                                                            {secret?.project?.name}
                                                        </td>
                                                        <td>
                                                            <Box sx={
                                                                {
                                                                    display: 'flex',
                                                                    gap: '1rem',

                                                                }
                                                            }>
                                                                <Button size="sm" variant="soft" color="primary"
                                                                        onClick={() => {
                                                                            setExistingSecret(secret)
                                                                            ToggleAddSecretForm()
                                                                        }}>
                                                                    <FiEdit/>
                                                                </Button>
                                                                {
                                                                    currentUserId === secret?.createdBy?._id ?
                                                                        (
                                                                            <>
                                                                                <Button size="sm" variant="soft"

                                                                                        color="danger"
                                                                                        onClick={() => {
                                                                                            setDeleteAlert(true)
                                                                                            setDeleteID(secret._id)
                                                                                        }
                                                                                        }>
                                                                                    <AiOutlineDelete/>
                                                                                </Button>
                                                                            </>
                                                                        )
                                                                        : null
                                                                }

                                                            </Box>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                    :
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <img src={'/no_data.jpg'} alt={'img'} width={'50%'}/>
                                    </div>
                            }
                        </Box>
                    </MainBody>
                </ScreenWrapper>
                {/*{deleteAlert &&*/}
                {/*    <AlertModal*/}
                {/*        title="Delete  !"*/}
                {/*        subtitle="Do you really want to remove the Password?"*/}
                {/*        onConfirm={() => {*/}
                {/*            toast.promise(*/}
                {/*                axios.delete(*/}
                {/*                    `${process.env.REACT_APP_API_URL}passwords/${deleteID}`,*/}
                {/*                    {*/}
                {/*                        headers: {*/}
                {/*                            Authorization: `Bearer ${localStorage.getItem("token")}`,*/}
                {/*                        },*/}
                {/*                    }*/}
                {/*                ).then(() => {*/}
                {/*                    getSecretsWithOrg();*/}
                {/*                    setDeleteAlert(false)*/}
                {/*                }),*/}
                {/*                {*/}
                {/*                    loading: 'Deleting...',*/}
                {/*                    success: 'Password deleted successfully',*/}
                {/*                    error: 'Failed to delete'*/}
                {/*                }*/}
                {/*            )*/}

                {/*        }}*/}
                {/*        onCancel={*/}
                {/*            () => {*/}
                {/*                setDeleteAlert(false);*/}
                {/*            }*/}
                {/*        }*/}
                {/*    />*/}
                {/*}*/}
                <AddSecretForm show={showAddSecretForm} close={() => {
                    ToggleAddSecretForm()
                    setExistingSecret(false)
                }}
                               organisationId={params?.id}
                               existingSecret={existingSecret} onClose={
                    () => {
                        getSecretsWithOrg();
                        setExistingSecret(false);
                        ToggleAddSecretForm();
                    }
                } organisationMembers={organisationMembers} projects={projects}
                               currentUserId={currentUserId}
                />
            </>

            <Modal open={deleteAlert} onClose={() => setDeleteAlert(false)}>
                <ModalDialog variant="outlined" role="alertdialog">
                    <DialogTitle>
                        <ImWarning/>
                        Delete Credential
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        Are you sure you want to delete the credential ?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="solid" color="danger" onClick={
                            () => {
                                setDeleteAlert(false);
                                toast.promise(
                                    axios.delete(
                                        `${process.env.REACT_APP_API_URL}passwords/${deleteID}`,
                                        {
                                            headers: {
                                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                                            },
                                        }
                                    ).then(() => {
                                        getSecretsWithOrg();
                                        setDeleteAlert(false)
                                    }),
                                    {
                                        loading: 'Deleting...',
                                        success: 'Password deleted successfully',
                                        error: 'Failed to delete'
                                    }
                                )
                            }
                        }>
                            Delete
                        </Button>
                        <Button variant="plain" color="neutral"
                                onClick={() => setDeleteAlert(false)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>

    );
};

export default PasswordMangerView;
