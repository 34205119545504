import React from "react";
import styled from "styled-components";

const SearchBax = styled.div`
    margin-left: 20px;
    input{
        width: 300px;
        padding: 10px;
        border-radius: 12px;
        font-size: 16px;
        outline: none;
        border: 1px solid #e0e0e0;
    }
`

const  SearchBar = ({searchTerm,value}) => {
    return (<SearchBax className='search-box'>
        <input type='text' placeholder='Search...' value={searchTerm}
               onChange={(e) => value(e.target.value)}/>
    </SearchBax>)
}
export default SearchBar